import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import Loader from "components/Loader";
import Dropdown from "rc-dropdown";
import React, { useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAPIBaseUrl } from "utility/constants";

// const stripePromise = loadStripe(
//   "pk_test_51Q0ojDHG7saj5CIN9MTIVlUg8CT7OnuOvRNN7Deo1i7uOLbVlr2HTDZoyCsPxfv7mLlK4ko6dKiB1wRYr3cnL1yF00px5khdl9"
// );

const stripePromise = loadStripe(
  "pk_live_51Q0ojDHG7saj5CINiX3EaCv8ORQTIb9nyOaQvmQ3R0vs7LkuuQtqfH13vzlFsim7Gk0yAiJMbb4CK8GtHDPSlJ0300fE1xnbYY"
);

const Workspace = ({ userInfo }) => {
  const [selectedMenu, setSelectedMenu] = useState("all");
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [workspaceUsers, setWorkspaceUsers] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("Starter");
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const { currentUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const deleteUserHandler = async () => {
    const email = selectedUser.email;
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-user?email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("User deleted successfully!");
      fetchWorkspaceUsers();
      setShowModal(false);
    } catch (error) {
      toast.error("Failed to delete user.");
    }
  };

  const fetchWorkspaceUsers = async () => {
    try {
      const data = {
        email: currentUser?.email,
      };
      const response = await axios.post(
        `${getAPIBaseUrl()}/get-workspace-users`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data && response.data.workspace_users) {
        setWorkspaceUsers(response.data.workspace_users);
      } else {
        setWorkspaceUsers([]);
      }
    } catch (error) {
      toast.error("Failed to initiate checkout");
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = async () => {
    setLoading(true);

    const requestData = {
      user_emails: emails,
      admin_email: currentUser?.email,
      name: currentUser?.name,
      selectedPlan: selectedPlan === "Starter" ? "starter" : "premium",
    };
    try {
      const { data } = await axios.post(
        `${getAPIBaseUrl()}/create-workspace-checkout-session`,
        requestData
      );

      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({
        sessionId: data.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      toast.error("Failed to initiate checkout");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddEmail();
    }
  };
  const handleAddEmail = () => {
    if (inputValue.trim() !== "") {
      if (validateEmail(inputValue)) {
        setEmails([...emails, inputValue.trim()]);
        setInputValue("");
      } else {
        toast.error("Please enter a valid email!");
      }
    }
  };

  const validateEmail = (email) => {
    return /^[\w-.]+@[\w-]+\.[a-z]{2,}$/i.test(email);
  };

  const removeEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  useState(() => {
    fetchWorkspaceUsers();
  }, []);
  console.log("workspaceUsers", workspaceUsers);
  return (
    <div>
      <div className="text-xl border-b border-white pb-5 border-opacity-50">
        Members
      </div>
      <div className="flex flex-row pt-5 pb-3 gap-5">
        <div
          className={`text-sm opacity-70  rounded-xl  px-2 py-1 cursor-pointer
                    ${selectedMenu === "all" && "bg-chip-bg"}
                    `}
          onClick={() => {
            setSelectedMenu("all");
          }}
        >
          All Members
        </div>
        <div
          className={`text-sm opacity-70  rounded-xl  px-2 py-1 cursor-pointer
                    ${selectedMenu === "pending" && "bg-chip-bg"}
                    `}
          onClick={() => {
            setSelectedMenu("pending");
          }}
        >
          Pending Invites
        </div>
        {currentUser.user_role !== "analyst" && (
          <div
            className={`text-sm opacity-70  rounded-xl  px-2 py-1 cursor-pointer
                    ${selectedMenu === "invite" && "bg-chip-bg"}
                    `}
            onClick={() => {
              setSelectedMenu("invite");
            }}
          >
            Invite Member
          </div>
        )}
      </div>
      {selectedMenu === "all" ? (
        <div class="h-full overflow-y-auto pt-4">
          <table class="w-full text-sm text-left ">
            <thead class="text-xs  uppercase bg-chip-bg">
              <tr>
                <th scope="col" class="px-3 py-3">
                  Name
                </th>
                <th scope="col" class="px-3 py-3">
                  Email
                </th>
                <th scope="col" class="px-3 py-3">
                  Role
                </th>
                <th scope="col" class="px-3 py-3">
                  Tokens Used
                </th>
                <th scope="col" class="px-3 py-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {workspaceUsers && workspaceUsers.length > 0
                ? workspaceUsers.map((user) => {
                    if (user.status !== "pending") {
                      return (
                        <tr class=" border-b  border-gray-200 border-opacity-60">
                          <th scope="row" class="px-3 py-3">
                            {user?.name}
                          </th>
                          <td
                            class="px-3 py-4 "
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              WebkitLineClamp: 2,
                              wordBreak: "break-word",
                            }}
                          >
                            {" "}
                            {user?.email}
                          </td>
                          <td class="px-3 py-4">
                            {" "}
                            {user.user_role ? user.user_role : "admin"}
                          </td>
                          <td class="px-3 py-4"> {user?.tokens_used}</td>
                          <td>
                            {user.user_role === "analyst" &&
                              currentUser.user_role !== "analyst" && (
                                <button
                                  className={` bg-red-100 font-sans text-xs outline-none borer border-red-100 flex flex-row items-center justify-center 
              rounded-full py-2 px-3 
            `}
                                  onClick={() => {
                                    setShowModal(true);
                                    setSelectedUser(user);
                                  }}
                                >
                                  Delete
                                </button>
                              )}
                          </td>
                        </tr>
                      );
                    }
                  })
                : null}
            </tbody>
          </table>
        </div>
      ) : selectedMenu === "pending" ? (
        <div class="h-[25vh] overflow-x-auto pt-4">
          <table class="w-full text-sm text-left ">
            <thead class="text-xs  uppercase bg-chip-bg">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Email
                </th>
                <th scope="col" class="px-6 py-3">
                  Role
                </th>
              </tr>
            </thead>
            <tbody>
              {workspaceUsers && workspaceUsers.length > 0
                ? workspaceUsers.map((user) => {
                    if (user.status === "pending") {
                      return (
                        <tr class=" border-b  border-gray-200 border-opacity-60">
                          <td class="px-6 py-4"> {user?.email}</td>
                          <td class="px-6 py-4">
                            {" "}
                            {user.role ? user.role : "analyst"}
                          </td>
                        </tr>
                      );
                    }
                  })
                : null}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <div className="text-xl opacity-80  mt-3  mb-1">
            Invite members to the workspace
          </div>
          <div className="text-sm opacity-50 pb-2">
            This workspace is private, only select members and roles can use
            this workspace.
          </div>
          <div className="my-4 flex flex-row gap-6 justify-between">
            <div className="flex flex-row gap-6">
              <div className="text-sm opacity-70 font-bold">Select Plan</div>
              <Dropdown
                trigger={["click"]}
                align={{ offset: [0, 0] }}
                overlay={
                  <div className={`  w-[140%] bg-chip-bg`}>
                    <div
                      className={` opacity-70 py-2 rounded-t-lg cursor-pointer  px-3  border-b hover:bg-black-200 border-white border-opacity-70`}
                      onClick={() => {
                        setSelectedPlan("Starter");
                      }}
                    >
                      Starter
                    </div>
                    <div
                      className={` opacity-70 py-2 rounded-b-lg   cursor-pointer hover:bg-black-200 px-3 rounded-b-lg`}
                      onClick={() => {
                        setSelectedPlan("Premium");
                      }}
                    >
                      Premium
                    </div>
                  </div>
                }
              >
                <div className="flex flex-row gap-2 cursor-pointer">
                  <div className={` opacity-70 text-sm`}>{selectedPlan}</div>
                  <FaChevronDown size="16" className="mt-1" />
                </div>
              </Dropdown>
            </div>
            <div className="flex flex-row text-blue-50">
              <div
                className=" cursor-pointer pb-1 text-sm"
                onClick={() => navigate("/plans")}
              >
                Check Plans
              </div>
              <FaChevronRight className="mt-[3px] ml-2" />
            </div>
          </div>
          <div className="mt-3 w-full bg-chip-bg p-3 rounded-lg h-32">
            <div className="flex flex-wrap gap-2">
              {emails.map((email, index) => (
                <div
                  key={index}
                  className="flex items-center bg-gray-700 px-3 py-1 rounded-full text-sm"
                >
                  {email}
                  <button className="ml-2 " onClick={() => removeEmail(index)}>
                    ✕
                  </button>
                </div>
              ))}
              <div className="flex items-center border border-gray-300 rounded-lg px-3 py-2 w-full">
                <input
                  type="text"
                  className="bg-transparent outline-none flex-1"
                  placeholder="Type email & press enter..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button
                  className="ml-2 bg-blue-500 text-white px-4 py-1 rounded-md"
                  onClick={handleAddEmail}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-row justify-end">
            <button
              className={`w-24 flex flex-row justify-center items-center h-12 mt-5 bg-blue-50 text-text-light font-semibold rounded-2xl shadow-lg hover:brightness-125
 `}
              onClick={submitHandler}
            >
              {loading ? <Loader /> : "Submit"}
            </button>
          </div>
        </div>
      )}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-800 p-6 rounded-lg w-full max-w-sm text-center shadow-lg mx-4 sm:mx-0">
            <h2 className="text-xl font-semibold text-text-light mb-4">
              Confirm Delete
            </h2>
            <p className="text-gray-300 mb-6">
              Are you sure you want to delete {selectedUser?.name}?
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={deleteUserHandler}
                className="bg-red-600 hover:bg-red-700 text-text-light rounded-md font-semibold px-4 py-2"
              >
                Yes, Delete
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-600 hover:bg-gray-700 text-text-light rounded-md font-semibold px-4 py-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Workspace;
