import React, { useState } from "react";
import Markdown from "markdown-to-jsx";
import { IoRadioSharp } from "react-icons/io5";
import { MdContentCopy } from "react-icons/md";
import { AiOutlineLike } from "react-icons/ai";
import { AiOutlineDislike } from "react-icons/ai";
import regenerateIcon from "../assets/regenerate.png";
import { FaCheck } from "react-icons/fa6";
import copy from "copy-to-clipboard";
import { FaRegCircleStop } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { MdRestartAlt } from "react-icons/md";
import { LuBrainCircuit } from "react-icons/lu";
import ChatStyle from "../pages/ChatScreen/css/Chat.module.css";

const ChatCard = ({
  message,
  regenerateHandler,
  likeMsgHandler,
  dislikeMsgHandler,
  isVisible = false,
  isStreaming = false,
  uploadedFiles = [],
  theme = "dark",
}) => {
  const [isHovered, setHovered] = useState(false);
  const [isCopy, setCopy] = useState(false);
  const [isSpeechStarted, setSpeechStarted] = useState(false);

  const { role, content, isLiked, isDisliked } = message;

  const speakAloudText = () => {
    if ("speechSynthesis" in window) {
      setSpeechStarted(true);
      const utterance = new SpeechSynthesisUtterance(content);
      utterance.lang = "en-US";
      utterance.rate = 1;
      utterance.pitch = 1;

      utterance.onend = () => {
        setSpeechStarted(false);
      };

      utterance.onerror = (event) => {
        console.error("Speech synthesis error:", event.error);
        setSpeechStarted(false);
      };

      window.speechSynthesis.speak(utterance);
    } else {
      console.error("Sorry, your browser does not support text to speech!");
      setSpeechStarted(false);
    }
  };

  const stopAloudText = () => {
    window.speechSynthesis.cancel();
    setSpeechStarted(false);
  };
  const copyHandler = () => {
    copy(content);

    setCopy(true);

    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  return (
    <div className={` w-full mb-2.5 `}>
      <div className="w-full flex flex-row">
        <div className="w-full">
          {role === "user" ? (
            <div className="w-full flex flex-row justify-end">
              <div className="w-fit">
                <div>
                  {uploadedFiles &&
                    uploadedFiles.length > 0 &&
                    uploadedFiles.map((file) => {
                      const fileType = file.file_type;

                      return fileType.startsWith("image/") ? (
                        <div className="relative">
                          <img
                            src={file.file_url}
                            alt="Uploaded"
                            className={`w-20 h-20  p-2 border rounded-[20px] ${
                              theme === "dark" ? "border-white" : "border-black"
                            } border-opacity-20 mb-2 `}
                          />
                        </div>
                      ) : (
                        <a
                          href={file.file_url}
                          download={file.file_name}
                          className={` flex flex-row gap-4  p-2 border border-opacity-20 ${
                            theme == "dark"
                              ? "border-white text-white bg-black-200"
                              : "border-black text-black bg-neutral-50"
                          }  m-3 rounded-[20px]`}
                        >
                          <FaFileAlt className="w-8 h-8" />
                          <div>
                            <div className="text-sm">{file.file_name}</div>
                            <div
                              className={`text-xs ${
                                theme === "dark" ? "text-white" : "text-black"
                              } opacity-60`}
                            >
                              {fileType}
                            </div>
                          </div>
                        </a>
                      );
                    })}
                </div>
                <div className={ChatStyle.userMessage}>
                  <div>
                    <Markdown
                      children={content.replace(/\n\n/g, "<br/><br/>")}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                onMouseEnter={() => {
                  setHovered(true);
                }}
                onMouseLeave={() => {
                  setHovered(false);
                }}
              >
                <div
                  className={`flex flex-row gap-2 rounded-[20px] text-text-dark text-opacity-70`}
                >
                  <div className={ChatStyle.avatar}>
                    <LuBrainCircuit />
                  </div>
                  <Markdown
                    options={{
                      overrides: {
                        h1: {
                          component: "h",
                          props: { className: "text-3xl  mt-4 mb-2" },
                        },
                        h3: {
                          component: "h3",
                          props: { className: "text-xl  mt-4 mb-2" },
                        },
                        h2: {
                          component: "h2",
                          props: { className: "text-2xl  mt-6 mb-3" },
                        },
                        p: { component: "p", props: { className: "mb-4" } },
                        code: {
                          component: "code",
                          props: {
                            className: ` ${
                              theme === "dark"
                                ? "bg-transparent text-white"
                                : "bg-transparent text-black"
                            } text-opacity-70 rounded-[20px] p-1 my-4`,
                          },
                        },
                        pre: {
                          component: "pre",
                          props: {
                            className: ` ${
                              theme === "dark"
                                ? "bg-black-200"
                                : "bg-neutral-50"
                            } rounded-[20px] p-2 overflow-x-auto my-4 `,
                          },
                        },
                      },
                    }}
                    children={content}
                  />
                </div>
                {/* {!isStreaming && (isHovered || isVisible) ? (
                  <div
                    className={` ${
                      theme === "dark" ? "bg-black-100" : "bg-white"
                    } flex flex-row gap-2 ml-12 w-fit mb-4`}
                  >
                    <div
                      className={`${
                        theme === "dark"
                          ? "hover:bg-black-200 text-white"
                          : "hover:bg-neutral-50 text-black"
                      } p-2 rounded-lg flex flex-row items-center justify-center  cursor-pointer`}
                      onClick={isSpeechStarted ? stopAloudText : speakAloudText}
                    >
                      {isSpeechStarted ? (
                        <FaRegCircleStop className="w-4 h-4" />
                      ) : (
                        <IoRadioSharp className="w-4 h-4" />
                      )}
                    </div>
                    <div
                      className={`${
                        theme === "dark"
                          ? "hover:bg-black-200 text-white"
                          : "hover:bg-neutral-50 text-black"
                      } p-2 rounded-lg flex flex-row items-center justify-center  cursor-pointer`}
                      onClick={copyHandler}
                    >
                      {isCopy ? (
                        <FaCheck
                          size={16}
                          color={theme === "dark" ? "white" : "black"}
                        />
                      ) : (
                        <MdContentCopy className="w-4 h-4" />
                      )}
                    </div>
                    {isVisible && (
                      <div
                        className={`${
                          theme === "dark"
                            ? "hover:bg-black-200 text-white"
                            : "hover:bg-neutral-50 text-black"
                        } p-2 rounded-lg flex flex-row items-center justify-center  cursor-pointer`}
                        onClick={() => {
                          regenerateHandler(content);
                        }}
                      >
                        <MdRestartAlt className="w-4 h-4" />
                      </div>
                    )}
                    {!isLiked && !isDisliked ? (
                      <div className="flex flex-row">
                        <div
                          className={`${
                            theme === "dark"
                              ? "hover:bg-black-200 text-white"
                              : "hover:bg-neutral-50 text-black"
                          } p-2 rounded-lg flex flex-row items-center justify-center  cursor-pointer`}
                          onClick={likeMsgHandler}
                        >
                          <AiOutlineLike className="w-4 h-4" />
                        </div>
                        <div
                          className={`${
                            theme === "dark"
                              ? "hover:bg-black-200 text-white"
                              : "hover:bg-neutral-50 text-black"
                          } p-2 rounded-lg flex flex-row items-center justify-center  cursor-pointer`}
                          onClick={dislikeMsgHandler}
                        >
                          <AiOutlineDislike className="w-4 h-4" />
                        </div>
                      </div>
                    ) : isLiked ? (
                      <div
                        className={`${
                          theme === "dark"
                            ? "hover:bg-black-200 text-white"
                            : "hover:bg-neutral-50 text-black"
                        } p-2 rounded-lg flex flex-row items-center justify-center`}
                        onClick={likeMsgHandler}
                      >
                        <AiOutlineLike className="w-4 h-4" />
                      </div>
                    ) : (
                      <div
                        className={`${
                          theme === "dark"
                            ? "hover:bg-black-200 text-white"
                            : "hover:bg-neutral-50 text-black"
                        } p-2 rounded-lg flex flex-row items-center justify-center`}
                        onClick={dislikeMsgHandler}
                      >
                        <AiOutlineDislike className="w-4 h-4" />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="mb-12"></div>
                )} */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatCard;
