import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "../../assets/google-icon.png";
import microsoftIcon from "../../assets/microsoft.png";
import { useGoogleLogin } from "@react-oauth/google";
import {
  setUserActionType,
  setUserDetails,
} from "../../redux/actions/authActions";
import { toast } from "react-toastify";
import { getAPIBaseUrl } from "../../utility/constants";
import axios from "axios";
import { useDispatch } from "react-redux";
import Loader from "../../components/Loader";
import { LuSun } from "react-icons/lu";
import { FaArrowRight, FaMobile, FaMoon, FaTimes } from "react-icons/fa";
import ggDarkLogo from "../../assets/gglogo-dark.png";
import aiImage from "../../assets/gg-bg-image-new.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { CustomModal } from "../../components/CustomModal";
import { useMediaQuery } from "react-responsive";
import backgroundImage from "../../assets/background.png";
import Select from "react-select";
import { companySizes, industryOptions } from "../../utils";
import { IoMdInformationCircleOutline } from "react-icons/io";
import Dropdown from "rc-dropdown";
import loginImage from "../../assets/login-bg.png";
import { BiArrowToRight, BiSolidRightArrow } from "react-icons/bi";
import { FaChevronRight } from "react-icons/fa6";
import Marquee from "react-fast-marquee";
import { browserName, osName } from "react-device-detect";

const styles = {
  input:
    "border-[1.5px] focus:border-opacity-50 px-3 py-4 placeholder-gray-500 text-black-900 rounded-2xl focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm",
};

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#F2F5F7", // Adjust to match the background color (purple)
    border: "none",
    color: "white",
    "&:hover": {
      borderColor: "#3B364B",
    },
    minHeight: "44px",
    fontSize: 14,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#6b7280",
    fontSize: 14,
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#3B364B", // Menu background color
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#2C2738" : "#3B364B", // Focused option color
    color: "white",
    "&:hover": {
      backgroundColor: "#2C2738",
    },
    cursor: "pointer",
  }),
};

const Signup = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [addressState, setAddressState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [userType, setUserType] = useState("individual");
  const [enterpriseUniqueId, setEnterpriseUniqeuId] = useState("");
  const [authUrl, setAuthUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setSignupErrorMessage] = useState("");
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light")
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    minLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });
  const [isPrivacyChecked, setPrivacyChecked] = useState(false);
  const [isVerifyEmailModal, setVerifyEmailModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verifyCodeLoading, setVerifyCodeLoading] = useState(false);
  const [selectedStep, setSelectedStep] = useState("personalInfo");

  const navigate = useNavigate();
  const storeDispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const nextHandler = () => {
    setSelectedStep("orgInfo");
  };

  const resetUserDetails = () => {
    setEmail("");
    setPassword("");
    setFirstName("");
    setLastName("");
    setConfirmPassword("");
    setPhoneNumber("");
    setRole("");
    setCompanyName("");
    setCompanyAddress("");
    setCompanySize("");
    setZipCode("");
    setAddressState("");
    setSelectedIndustry("");
    setSignupErrorMessage("");
    setIsSubmitted(false);
  };
  const verifyUserHandler = async (e) => {
    e.stopPropagation();

    setVerifyCodeLoading(true);
    const data = {
      email: email,
      verification_code: verificationCode,
    };

    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/verify-user`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/billing");
    } catch (error) {
      toast.error("Invalid code ! Try again");
    } finally {
      setVerifyCodeLoading(false);
    }
  };
  const enterpriseSignupHandler = async () => {
    setLoading(true);
    setIsSubmitted(true);

    if (
      !isPrivacyChecked ||
      !firstName ||
      !lastName ||
      !email ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ||
      !companyName ||
      !selectedIndustry ||
      !companySize ||
      !companyAddress ||
      !zipCode ||
      !addressState ||
      !password ||
      !passwordStrength.minLength ||
      !passwordStrength.hasUppercase ||
      !passwordStrength.hasLowercase ||
      !passwordStrength.hasNumber ||
      !passwordStrength.hasSpecialChar ||
      password !== confirmPassword
    ) {
      setLoading(false);
      return;
    }

    let data = {
      user_email: email,
      user_name: `${firstName} ${lastName}`,
      user_contact_number: phoneNumber,
      user_role: role,
      enterprise_name: companyName,
      industry: selectedIndustry,
      company_size: companySize,
      company_address: companyAddress,
      zip_code: zipCode,
      state: addressState,
      password: password,
    };

    storeDispatch(setUserActionType("enterprise"));

    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/create-enterprise-account`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const user = {
        ...response?.data?.user,
        userType: "enterprise",
      };
      storeDispatch(setUserDetails(user));
      setLoading(false);
      setSignupErrorMessage("");
      toast.success("Enterprise account created!");
      setTimeout(() => {
        navigate("/billing?user-type=enterprise");
      }, 3000);
    } catch (error) {
      storeDispatch(setUserDetails({}));
      setLoading(false);
      setSignupErrorMessage(error?.response?.data?.error);
    } finally {
      setIsSubmitted(false);
    }
  };
  const signupHandler = async () => {
    setLoading(true);
    setIsSubmitted(true);

    if (
      !isPrivacyChecked ||
      !firstName ||
      !lastName ||
      !email ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ||
      password !== confirmPassword ||
      !passwordStrength.minLength ||
      !passwordStrength.hasUppercase ||
      !passwordStrength.hasLowercase ||
      !passwordStrength.hasNumber ||
      !passwordStrength.hasSpecialChar ||
      !phoneNumber
    ) {
      setLoading(false);
      return;
    }

    let data = {
      email,
      password,
      name: `${firstName} ${lastName}`,
      phoneNumber,
      deviceType: `${browserName} on ${osName}`,
    };

    storeDispatch(setUserActionType("individual"));

    try {
      const response = await axios.post(`${getAPIBaseUrl()}/signup`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response && response.data && response.data.is_verified) {
        const user = {
          ...response?.data?.user,
          userType: "individual",
        };

        storeDispatch(setUserDetails(user));
        setLoading(false);
        setSignupErrorMessage("");
        toast.success("User account created successfully");
        navigate("/billing");
      } else {
        storeDispatch(setUserDetails(response?.data?.user));
        setLoading(false);
        setSignupErrorMessage("");
        setVerifyEmailModal(true);
      }
    } catch (error) {
      storeDispatch(setUserDetails({}));
      setLoading(false);
      setSignupErrorMessage(error?.response?.data?.error);
    } finally {
      setIsSubmitted(false);
    }
  };

  const handleSuccess = async (response) => {
    try {
      const token = response.access_token;

      const user_info = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (user_info && Object.keys(user_info).length > 0) {
        const { name, email, given_name, family_name } = user_info.data;

        const data = {
          name: name ? name : given_name + family_name,
          email,
        };
        const response = await axios.post(
          `${getAPIBaseUrl()}/google-login`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response && response.data) {
          toast.success("Logged in successfully");
          storeDispatch(setUserDetails(response?.data?.user));
          if (response.data.code === "1") {
            navigate("/");
          } else {
            navigate("/billing");
          }
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.error, { autoClose: 5000 });
    }
  };

  const googleLoginHandler = useGoogleLogin({
    onSuccess: async (tokenResponse) => handleSuccess(tokenResponse),
  });

  const msLoginHandler = () => {
    if (authUrl) {
      window.location.href = authUrl;
    }
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);

    const newStrength = {
      minLength: passwordValue.length >= 12,
      hasUppercase: /[A-Z]/.test(passwordValue),
      hasLowercase: /[a-z]/.test(passwordValue),
      hasNumber: /[0-9]/.test(passwordValue),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(passwordValue),
    };

    setPasswordStrength(newStrength);
  };

  const reward = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("via") ? params.get("via") : null;
  }, []);

  useEffect(() => {
    const fetchAuthUrl = async () => {
      const response = await axios.get(`${getAPIBaseUrl()}/ms-auth-url`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setAuthUrl(response.data.auth_url);
    };
    fetchAuthUrl();
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

  useEffect(() => {
    if (reward) {
      localStorage.setItem("reward", reward);
    }
  }, [reward]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 text-white bg-white min-h-dvh">
      <div className="p-4 h-dvh hidden md:block">
        <img
          alt="AgentGG Background"
          src={loginImage}
          className="rounded-3xl object-contain h-full w-full bg-[black]"
        />
      </div>

      <div className="p-4">
        <div className="flex flex-col justify-center items-center max-w-md h-full mx-auto">
          <h1 className="text-center text-4xl md:text-5xl text-black-1100 font-semibold max-w-sm mb-4">
            Create your account
          </h1>

          <div className="grid w-full gap-3 grid-cols-2 text-black-800 mt-4">
            <button
              className="hover:opacity-90 w-full flex items-center justify-center py-2 border border-slate-50 rounded-2xl shadow-card bg-slate-200 focus:outline-none"
              onClick={googleLoginHandler}
            >
              <img alt="Google" src={GoogleIcon} className="w-6 h-6 mr-3" />
              <div className="font-medium text-sm">Google</div>
            </button>
            <button
              className="hover:opacity-90 w-full flex items-center justify-center py-2 border border-slate-50 rounded-2xl shadow-card bg-slate-200 focus:outline-none"
              onClick={msLoginHandler}
            >
              <img
                alt="Microsoft"
                src={microsoftIcon}
                className="w-6 h-6 mr-3"
              />
              <div className="font-medium text-sm">Microsoft</div>
            </button>
          </div>
          <div className="w-full flex items-center justify-center py-4">
            <div className="w-full flex gap-2 items-center justify-center">
              <div className="h-[1px] grow bg-black-1100 bg-opacity-10"></div>
              <span className="text-sm text-black-1100 opacity-60 text-nowrap">
                or with email
              </span>
              <div className="h-[1px] grow bg-black-1100 bg-opacity-10"></div>
            </div>
          </div>
          <div className="w-full flex text-sm justify-end mb-3">
            <div
              className="cursor-pointer flex gap-2 hover:opacity-70 active:text-blue-200 justify-center items-center text-blue-50"
              onClick={() => navigate("/plans")}
            >
              <div>View pricing</div>
              <FaChevronRight size={10} />
            </div>
          </div>

          <div className="w-full text-black-1100 text-opacity-50 flex flex-col gap-3">
            <div className="flex flex-col w-full gap-3">
              {userType === "individual" ? (
                <>
                  <div className="flex flex-col gap-2 grow">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex flex-col gap-2 grow">
                        <label htmlFor="first-name" className="sr-only">
                          First name
                        </label>
                        <input
                          type="text"
                          autoComplete="name"
                          required
                          className={styles.input}
                          placeholder="First name"
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                          value={firstName}
                        />
                        {isSubmitted && !firstName && (
                          <span className="text-red-100 text-[13px]">
                            Required
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col gap-2 grow">
                        <label htmlFor="last-name" className="sr-only">
                          Last name
                        </label>
                        <input
                          type="text"
                          autoComplete="name"
                          required
                          className={styles.input}
                          placeholder="Last name"
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                          value={lastName}
                        />
                        {isSubmitted && !lastName && (
                          <span className="text-red-100 text-[13px]">
                            Required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 grow">
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className={styles.input}
                      placeholder="Email address"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                    />
                    {isSubmitted &&
                      (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) && (
                        <span className="text-red-100 text-[13px]">
                          Please enter a valid email
                        </span>
                      )}
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col gap-2 grow">
                      <label htmlFor="password" className="sr-only">
                        Password
                      </label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className={styles.input}
                        placeholder="Password"
                        onChange={handlePasswordChange}
                        value={password}
                      />
                      {isSubmitted && (
                        <div className="">
                          <p
                            className={`text-[13px] ${
                              passwordStrength.minLength
                                ? "text-green-200"
                                : "text-red-100"
                            }`}
                          >
                            {!passwordStrength.minLength &&
                              "❌ At least 12 characters"}
                          </p>
                          <p
                            className={`text-[13px] ${
                              passwordStrength.hasUppercase
                                ? "text-green-200"
                                : "text-red-100"
                            }`}
                          >
                            {!passwordStrength.hasUppercase &&
                              "❌ At least one uppercase letter"}
                          </p>
                          <p
                            className={`text-[13px] ${
                              passwordStrength.hasLowercase
                                ? "text-green-200"
                                : "text-red-100"
                            }`}
                          >
                            {!passwordStrength.hasLowercase &&
                              "❌ At least one lowercase letter"}
                          </p>
                          <p
                            className={`text-[13px] ${
                              passwordStrength.hasNumber
                                ? "text-green-200"
                                : "text-red-100"
                            }`}
                          >
                            {!passwordStrength.hasNumber &&
                              "❌ At least one number"}
                          </p>
                          <p
                            className={`text-[13px] ${
                              passwordStrength.hasSpecialChar
                                ? "text-green-200"
                                : "text-red-100"
                            }`}
                          >
                            {!passwordStrength.hasSpecialChar &&
                              "❌ At least one special character"}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col gap-2 grow">
                      <label htmlFor="confirm-password" className="sr-only">
                        Confirm Password
                      </label>
                      <input
                        id="confirm-password"
                        name="confirm-password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className={styles.input}
                        placeholder="Confirm password"
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                        value={confirmPassword}
                      />
                      {isSubmitted && password !== confirmPassword && (
                        <span className="text-red-100 text-[13px]">
                          Passwords mismatch
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="phone-input-container">
                    <PhoneInput
                      placeholder="Phone number"
                      value={phoneNumber}
                      onChange={(value) => {
                        setPhoneNumber(value);
                      }}
                    />
                  </div>
                  {isSubmitted && !phoneNumber && (
                    <span className="text-red-100 text-[13px] ">
                      Please enter your phone number
                    </span>
                  )}
                  <div className="pb-2">
                    <div className="flex items-center my-3">
                      <input
                        type="checkbox"
                        value={isPrivacyChecked}
                        class="w-4 h-4  mt-1 accent-blue-50 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-0 dark:bg-gray-700 dark:border-gray-600"
                        onChange={(e) => {
                          setPrivacyChecked(e.target.checked);
                        }}
                      />
                      <div className="ms-2 text-sm font-medium text-black-900 mt-0.5">
                        I agree to the{" "}
                        <span
                          className="text-blue-50 font-bold cursor-pointer mt-1"
                          onClick={() => {
                            navigate("/privacy-policy");
                          }}
                        >
                          Privacy Policy
                        </span>{" "}
                        and{" "}
                        <span
                          className="text-blue-50 font-bold cursor-pointer"
                          onClick={() => {
                            navigate("/terms-of-service");
                          }}
                        >
                          Terms of Service
                        </span>
                      </div>
                    </div>
                    {isSubmitted && !isPrivacyChecked && (
                      <div className="text-red-100 text-[13px]  mt-0.5">
                        Please check the terms and conditions
                      </div>
                    )}
                  </div>
                  {errorMessage && (
                    <span className="text-red-100 text-sm flex flex-row justify-center pb-1">
                      {errorMessage}
                    </span>
                  )}
                  <button
                                      className={`w-full h-12 bg-blue-50 text-white font-semibold rounded-2xl shadow-lg hover:brightness-125
                                        ${loading ? "opacity-70 cursor-not-allowed" : "cursor-pointer"}`}
                    onClick={signupHandler}
                  >
                    {loading ? <Loader /> : "Create Account"}
                  </button>

                  <span className="w-full flex items-center justify-center mt-3 text-black-1100 text-opacity-50">
                    Already have an account?{" "}
                    <div
                      className="inline text-blue-50 hover:opacity-70 cursor-pointer ml-2"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </div>
                  </span>
                </>
              ) : (
                <div className="mt-6">
                  <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                    <li
                      className={`flex md:w-full items-center cursor-pointer mx-4 ${
                        selectedStep === "personalInfo"
                          ? "text-blue-50 "
                          : "text-gray-500 "
                      } sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700`}
                      onClick={() => {
                        setSelectedStep("personalInfo");
                      }}
                    >
                      <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                        <span className="me-2">1</span>
                        Personal
                        <span className="hidden sm:inline-flex sm:ms-2">
                          Info
                        </span>
                      </span>
                    </li>
                    <li
                      className={`flex md:w-full items-center cursor-pointer ${
                        selectedStep === "orgInfo"
                          ? "text-blue-50 "
                          : "text-gray-500 "
                      } after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700`}
                      onClick={() => {
                        setSelectedStep("orgInfo");
                      }}
                    >
                      <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                        <span className="me-2">2</span>
                        Organization
                        <span className="hidden sm:inline-flex sm:ms-2">
                          Info
                        </span>
                      </span>
                    </li>
                  </ol>
                  {selectedStep === "personalInfo" ? (
                    <div className="mx-4">
                      <div className="my-6 ">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div>
                            <label htmlFor="first-name" className="sr-only">
                              First Name
                            </label>
                            <input
                              type="text"
                              autoComplete="name"
                              required
                              className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              placeholder="First Name"
                              onChange={(e) => {
                                setFirstName(e.target.value);
                              }}
                              value={firstName}
                            />
                            {isSubmitted && !firstName && (
                              <span className="text-red-100 text-[13px]">
                                Please enter first name
                              </span>
                            )}
                          </div>
                          <div>
                            <label htmlFor="last-name" className="sr-only">
                              Last Name
                            </label>
                            <input
                              type="text"
                              autoComplete="name"
                              required
                              className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              placeholder="Last Name"
                              onChange={(e) => {
                                setLastName(e.target.value);
                              }}
                              value={lastName}
                            />
                            {isSubmitted && !lastName && (
                              <span className="text-red-100 text-[13px]">
                                Please enter last name
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <label htmlFor="email-address" className="sr-only">
                          Email address
                        </label>
                        <input
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          placeholder={
                            userType === "individual"
                              ? "Email address"
                              : "Official Email address"
                          }
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          value={email}
                        />
                        {isSubmitted &&
                          (!email ||
                            !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) && (
                            <span className="text-red-100 text-[13px] ">
                              Please enter a valid email
                            </span>
                          )}
                      </div>
                      <div className="pt-6">
                        <label htmlFor="password" className="sr-only">
                          Password
                        </label>
                        <div className="flex flex-row gap-3">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Set Password"
                            onChange={handlePasswordChange}
                            value={password}
                          />
                          <Dropdown
                            trigger={["hover"]}
                            // align={{ offset: [-20, 0] }}
                            overlay={
                              <div className="bg-black-100 text-white px-3 py-2 rounded-lg">
                                <div className="py-1 text-xs">
                                  Must be atleast 12 characters
                                </div>
                                <div className="py-1 text-xs">
                                  Must contain atleast one uppercase character{" "}
                                </div>
                                <div className="py-1 text-xs">
                                  Must contain atleast one lowercase character
                                </div>
                                <div className="py-1 text-xs">
                                  {" "}
                                  Must contain atleast one digit [0 -9]
                                </div>
                                <div className="py-1 text-xs">
                                  {" "}
                                  Must contain atleast one special charcter e.g
                                  @,!,* etc{" "}
                                </div>
                              </div>
                            }
                          >
                            <IoMdInformationCircleOutline
                              color="white"
                              size={24}
                              className="mt-3"
                            />
                          </Dropdown>
                        </div>
                        <div className="pt-6">
                          <label htmlFor="password" className="sr-only">
                            Confirm Password
                          </label>
                          <div className="flex flex-row gap-3">
                            <input
                              id="password"
                              name="password"
                              type="password"
                              autoComplete="current-password"
                              required
                              className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              placeholder="Confirm Password"
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                              }}
                              value={confirmPassword}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="phone-input-container w-full mb-3">
                        <PhoneInput
                          placeholder="Contact number for further communication"
                          value={phoneNumber}
                          onChange={(value) => {
                            setPhoneNumber(value);
                          }}
                        />
                      </div>
                      <div className="">
                        <label htmlFor="email-address" className="sr-only">
                          Role in Company
                        </label>
                        <input
                          type="text"
                          required
                          className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          placeholder="Role in the company"
                          onChange={(e) => {
                            setRole(e.target.value);
                          }}
                          value={role}
                        />
                        {/* {isSubmitted &&
                          (!email ||
                            !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) && (
                            <span className="text-red-100 text-[13px] ">
                              Please enter a valid email
                            </span>
                          )} */}
                      </div>
                      <div className="mt-6">
                        <button
                          className={`w-full flex justify-center items-center h-12 py-3 bg-blue-50 text-white font-semibold rounded-full shadow-lg transition
${loading ? "opacity-70 cursor-not-allowed" : ""} 
${
  !firstName || !lastName || !email || !role
    ? "opacity-50 cursor-not-allowed"
    : "hover:from-purple-700 hover:to-indigo-700"
}`}
                          onClick={nextHandler}
                          disabled={
                            !firstName ||
                            !lastName ||
                            !email ||
                            !role ||
                            !password ||
                            !passwordStrength.minLength ||
                            !passwordStrength.hasUppercase ||
                            !passwordStrength.hasLowercase ||
                            !passwordStrength.hasNumber ||
                            !passwordStrength.hasSpecialChar ||
                            password !== confirmPassword
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="mx-4">
                      <div className="my-6">
                        <div className="">
                          <label htmlFor="email-address" className="sr-only">
                            Company Name
                          </label>
                          <input
                            name="companyName"
                            type="email"
                            autoComplete="email"
                            required
                            className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Company / Organization Name"
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                            }}
                            value={companyName}
                          />
                        </div>
                      </div>
                      <div className="my-6">
                        <div className="">
                          <label htmlFor="email-address" className="sr-only">
                            Industry
                          </label>
                          <Select
                            options={industryOptions}
                            styles={customSelectStyles}
                            placeholder="Select Industry"
                            onChange={(e) => {
                              setSelectedIndustry(e.value);
                            }}
                            value={industryOptions.filter(
                              (industry) => industry.value === selectedIndustry
                            )}
                          />
                        </div>
                      </div>
                      <div className="my-6">
                        <div className="">
                          <label htmlFor="email-address" className="sr-only">
                            Company Size
                          </label>
                          <Select
                            options={companySizes}
                            styles={customSelectStyles}
                            placeholder="Company Size"
                            onChange={(e) => {
                              setCompanySize(e.value);
                            }}
                            value={companySizes.filter(
                              (company) => company.value === companySize
                            )}
                          />
                        </div>
                      </div>
                      <div className="my-6">
                        <label htmlFor="email-address" className="sr-only">
                          Company Address (Line 1)
                        </label>
                        <input
                          name="companyAddress"
                          type="text"
                          required
                          className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          placeholder="Company Address (Line 1)"
                          onChange={(e) => {
                            setCompanyAddress(e.target.value);
                          }}
                          value={companyAddress}
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="">
                          <label htmlFor="email-address" className="sr-only">
                            State
                          </label>
                          <input
                            name="city"
                            type="text"
                            required
                            className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="State"
                            onChange={(e) => {
                              setAddressState(e.target.value);
                            }}
                            value={addressState}
                          />
                        </div>
                        <div className="">
                          <label htmlFor="email-address" className="sr-only">
                            Zip Code
                          </label>
                          <input
                            name="zipCode"
                            type="text"
                            required
                            className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Zip Code"
                            onChange={(e) => {
                              setZipCode(e.target.value);
                            }}
                            value={zipCode}
                          />
                        </div>
                      </div>
                      <div className="pt-2">
                        <div className="flex items-center my-3">
                          <input
                            type="checkbox"
                            value={isPrivacyChecked}
                            class="w-4 h-4  mt-1 accent-blue-50 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-0 dark:bg-gray-700 dark:border-gray-600"
                            onChange={(e) => {
                              setPrivacyChecked(e.target.checked);
                            }}
                          />
                          <div className="ms-2 text-sm font-medium text-black-900 mt-0.5">
                            I agree to the{" "}
                            <span
                              className="text-blue-50 font-bold cursor-pointer mt-1"
                              onClick={() => {
                                navigate("/privacy-policy");
                              }}
                            >
                              Privacy Policy
                            </span>{" "}
                            and{" "}
                            <span
                              className="text-blue-50 font-bold cursor-pointer"
                              onClick={() => {
                                navigate("/terms-of-service");
                              }}
                            >
                              Terms of Service
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6">
                        <button
                          className={`w-full flex justify-center items-center h-12 py-3 bg-blue-50 text-white font-semibold rounded-full shadow-lg 
${
  loading ||
  !companyName ||
  !selectedIndustry ||
  !companySize ||
  !companyAddress ||
  !zipCode ||
  !addressState ||
  !isPrivacyChecked
    ? "opacity-50 cursor-not-allowed"
    : "hover:from-purple-700 hover:to-indigo-700 transition"
}`}
                          onClick={enterpriseSignupHandler}
                          disabled={
                            !companyName ||
                            !selectedIndustry ||
                            !companySize ||
                            !companyAddress ||
                            !zipCode ||
                            !addressState ||
                            !isPrivacyChecked
                          }
                        >
                          {loading ? <Loader /> : "Create Account"}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={isVerifyEmailModal}
        handleClose={() => {
          setVerifyEmailModal(false);
        }}
        theme={theme}
      >
        <div className="flex  px-4 text-center bg-white py-5 px-10 w-full text-black-1100">
          <div className="  rounded-lg  pt-2 pb-4 w-full">
            <div className="w-full flex items-start justify-between">
              <h3 className="text-2xl leading-6 font-medium ">Verify Email</h3>
              <button
                className="text-gray-400 dark:text-gray-200 hover:text-gray-500 focus:outline-none cursor-pointer"
                onClick={() => {
                  setVerifyEmailModal(false);
                }}
              >
                <FaTimes className="w-5 h-5" color="white" />
              </button>
            </div>
            <div className="text-sm text-left mt-3">
              We have sent a verification code to your email
            </div>
            <div className="mt-4">
              <input
                type="number"
                className="appearance-none bg-slate-200 block w-full p-3 mb-5 placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter your verification code"
                value={verificationCode}
                onChange={(e) => {
                  setVerificationCode(e.target.value);
                }}
              />
            </div>

            <div className="mt-6">
              <button
                onClick={verifyUserHandler}
                className={`w-full cursor-pointer flex flex-row justify-center items-center  px-4 h-12 border border-transparent text-base font-medium rounded-md text-white bg-blue-50    focus:outline-none
                  ${loading ? "opacity-70 cursor-not-allowed" : ""}
                  `}
              >
                {verifyCodeLoading ? "Verifying..." : "Verify User"}
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Signup;
