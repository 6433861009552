import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const suggestions = [
  "Write a to-do list for a personal project",
  "Generate an email to reply to a job offer",
  "How does AI work in a technical capacity",
  "Design a workout plan for beginners",
];

export default function AssistantLanding({ handleInitialSuggestion }) {
  const [animate, setAnimate] = useState(false);

  const currentUser = useSelector((state) => state.auth.currentUser);
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good Morning,";
    if (hour < 18) return "Good Afternoon,";
    return "Good Evening,";
  };

  useEffect(() => {
    setTimeout(() => setAnimate(true), 300);
  }, []);

  return (
    <div className="  flex flex-col items-center ">
      <div className="text-center mt-4">
        <div className="text-4xl font-semibold text-white">
          {getGreeting()}{" "}
          <span className="text-blue-50">{currentUser?.name}</span>
        </div>
        <div className="text-xl text-gray-500 mt-2">What’s on your mind?</div>
      </div>
      <div className="w-full max-w-3xl mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {suggestions.map((suggestion, index) => (
          <div
            key={index}
            className={`rounded-xl shadow p-4 text-sm text-gray-700 bg-white transition-all duration-500 ease-out transform ${
              animate ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
            }`}
            style={{ transitionDelay: `${index * 100}ms` }}
            onClick={() => handleInitialSuggestion(suggestion)}
          >
            {suggestion}
          </div>
        ))}
      </div>
    </div>
  );
}
