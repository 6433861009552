import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { getAPIBaseUrl } from "../../utility/constants";

const AcceptInvitation = () => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setEmail(params.get("email"));
    setToken(params.get("token"));
  }, [location]);

  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    if (!password) {
      toast.error("Password is required");
      return;
    }

    setLoading(true);
    try {
      const data = {
        email: email,
        token: token,
        password: password,
        name: name,
      };
      const response = await axios.post(
        `${getAPIBaseUrl()}//accept-user-invitation`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Invitation accepted! Please login");
        navigate("/login");
      }
    } catch (error) {
      toast.error(error.response?.data?.error || "Error resetting password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-black-1100 text-white">
      <div className="flex flex-col justify-center items-center max-w-lg h-full mx-auto">
        <h1 className="text-center text-4xl md:text-5xl text-black-1100 font-semibold max-w-sm mb-1 text-white">
          Accept Invitation
        </h1>
        <p className="text-center text-sm  text-black-1100 font-semibold max-w-sm mb-4 text-white text-opacity-70">
          Please create your new password
        </p>

        <div className="w-full text-black-1100 text-opacity-50 flex flex-col gap-3">
          <div className="flex flex-col gap-2 grow">
            <label htmlFor="email-address" className="text-sm">
              Email Address
            </label>
            <input
              name="text"
              type="email"
              autoComplete="email"
              required
              className="border-[1.5px] bg-white bg-opacity-70 cursor-not-allowed focus:border-opacity-50 px-3 py-4  text-black-900 rounded-2xl focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Name"
              value={email}
              disabled={true}
            />
          </div>
          <div className="flex flex-col gap-2 grow">
            <label htmlFor="email-address" className="text-sm">
              Name
            </label>
            <input
              name="text"
              type="email"
              autoComplete="email"
              required
              className="border-[1.5px] focus:border-opacity-50 px-3 py-4 placeholder-gray-500 text-black-900 rounded-2xl focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="password" className="text-sm">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="border-[1.5px] focus:border-opacity-50 px-3 py-4 placeholder-gray-500 text-black-900 rounded-2xl focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="password" className="text-sm">
              Confirm Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="border-[1.5px] focus:border-opacity-50 px-3 py-4 placeholder-gray-500 text-black-900 rounded-2xl focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
          </div>

          <div className="pt-6">
            <button
              onClick={handleResetPassword}
              className={`w-full py-1 h-12 mt-4 bg-gradient-to-r from-purple-600 to-indigo-600 rounded-lg text-white font-semibold ${
                loading ||
                !password ||
                !confirmPassword ||
                password !== confirmPassword ||
                !name
                  ? "opacity-50"
                  : ""
              }`}
              disabled={
                loading ||
                !password ||
                !confirmPassword ||
                password !== confirmPassword ||
                !name
              }
            >
              {loading ? <Loader /> : "Accept Invitation"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptInvitation;
