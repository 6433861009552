import { FaUser } from "react-icons/fa";
import SidebarStyle from "../css/Sidebar.module.css";
import { RiSettingsFill } from "react-icons/ri";
import { IoChatbubbleEllipses } from "react-icons/io5";
import { FaRightFromBracket } from "react-icons/fa6";

const SettingsButton = ({ setPage }) => {
  return (
    <>
      <div
        aria-label="Settings"
        role="button"
        className={SidebarStyle.item}
        onClick={() => setPage("settings")}
      >
        <RiSettingsFill size={18} className={SidebarStyle.icon} />
        <div className={SidebarStyle.label}>Settings</div>
      </div>
    </>
  );
};

const sidebarItems = [
  { label: "Chat", state: "chat", icon: IoChatbubbleEllipses },
];

const SidebarItems = ({
  currentPage,
  setPage,
  logoutUser,
  userType,
  currentUser,
  chatHistoryComp,
  newChatHandler
}) => {
  return (
    <>
      <div className={SidebarStyle.items}>
        {sidebarItems.map((item) => (
          <div
            aria-label={item.label}
            role="button"
            key={`item-${item.state}`}
            className={`${SidebarStyle.item} ${
              currentPage === item.state ? SidebarStyle.selected : ""
            }`}
            onClick={() => {
              setPage(item.state)
              newChatHandler()
            }}
          >
            <item.icon size={18} className={SidebarStyle.icon} />
            <div className={SidebarStyle.label}>{item.label}</div>
          </div>
        ))}
      </div>
      {chatHistoryComp}
      <div className={`${SidebarStyle.footer} pt-4`}>
        <div className={SidebarStyle.accountBox}>
          <div className={SidebarStyle.avatar}>
            <FaUser size={18} className={SidebarStyle.avatarIcon} />
          </div>
          <div className="w-full">
            <div className={`${SidebarStyle.name} text-white`}>
              {userType === "individual"
                ? currentUser &&
                  Object.keys(currentUser).length > 0 &&
                  currentUser.name
                  ? currentUser.name
                  : "Loading..."
                : currentUser && currentUser.user && currentUser.user.name
                ? currentUser.user.name
                : "Loading..."}
            </div>
            <div className={SidebarStyle.email}>
              {userType === "individual"
                ? currentUser &&
                  Object.keys(currentUser).length > 0 &&
                  currentUser.email
                  ? currentUser.email
                  : "..."
                : currentUser && currentUser.user && currentUser.user.email
                ? currentUser.user.email
                : "..."}
            </div>
          </div>
        </div>
        <SettingsButton setPage={setPage} />
        <div className={SidebarStyle.item}  onClick={logoutUser}>
          <FaRightFromBracket size={18} className={SidebarStyle.icon} />
          <div className={SidebarStyle.label}>
            Logout
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarItems;
