import React, { useContext, useEffect } from "react";
import ChatStyle from "./ChatStyle.module.css";
import ChatUiSkeleton from "../../components/ChatUiSkeleton";
import ChatCard from "../../components/ChatCard";
import Loader from "../../components/Loader";
import { IoIosAttach, IoMdArrowDropdown } from "react-icons/io";
import { AudioRecorder } from "react-audio-voice-recorder";
import { LuSend } from "react-icons/lu";
import {
  MdArrowDownward,
  MdDriveFileRenameOutline,
  MdOutlineExpandCircleDown,
} from "react-icons/md";
import {
  BiChevronUpCircle,
  BiDotsHorizontalRounded,
  BiDownArrow,
} from "react-icons/bi";
import Input from "../../components/Input";
import stopStreamIcon from "../../assets/stop-stream.png";
import { useNavigate } from "react-router-dom";
import { FaFileAlt } from "react-icons/fa";
import { IoEyeOutline, IoShareSocial } from "react-icons/io5";
import { GoDownload } from "react-icons/go";
import Dropdown from "rc-dropdown";
import "rc-dropdown/assets/index.css";
import { ImFilesEmpty } from "react-icons/im";
import { RiDeleteBin7Line } from "react-icons/ri";
import { aiModels } from "../../utils";
import { toast } from "react-toastify";
import ReactDragListView from "react-drag-listview/lib/index.js";
import { MdDragIndicator } from "react-icons/md";
import { ShepherdJourneyProvider, useShepherd } from "react-shepherd";
import "shepherd.js/dist/css/shepherd.css";

const UserChat = ({
  expanded,
  isChatMsgLoading,
  uploadedFiles,
  setIsScrolling,
  scrollContainerRef,
  chatMsgsToDisplay,
  isStreaming,
  loadingText,
  latestStream,
  rows,
  setRows,
  inputValue,
  setInputValue,
  regenerateHandler,
  likeMsgHandler,
  dislikeMsgHandler,
  scrollToBottom,
  dragOver,
  setDragOver,
  setShareChatModal,
  uploadFileHandler,
  renderUploadedFile,
  inputRef,
  handleKeyPress,
  showLoading,
  isUploadFileLoading,
  stopStreamingHandler,
  chatHistory,
  updatedChatTitle,
  files,
  isFilesExpanded,
  isListening,
  deleteChatHistory,
  selectedChat,
  chatInputRef,
  fileInputRef,
  uploadAudio,
  setFilesExpanded,
  newChatHandler,
  isChatHistoryExpanded,
  setChatHistoryExpanded,
  chatHistoryLoading,
  setUpdatedChatTitle,
  saveUpdatedChatTitle,
  handleChatHistory,
  setSelectedChatId,
  renameTitleHandler,
  theme,
  selectedAiModel,
  setSelectedAiModel,
  setResponseMode,
  responseMode,
}) => {
  const navigate = useNavigate();

  console.log("selectedAiModel", selectedAiModel);
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...selectedAiModel]; // Use selectedAiModel instead of that.state.data
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setSelectedAiModel(data); // Update the state with the new order
    },
    nodeSelector: "div", // Change to match the structure of your items
    handleSelector: "div", // Change to match the structure of your items
  };

  return (
    <div
      className={`${
        theme === "dark" ? "bg-black-900" : "bg-slate-50"
      } h-[calc(100dvh-2rem)] my-auto rounded-3xl w-full mr-4`}
    >
      <div
        className={`${ChatStyle.chatTopBar} w-full  ${
          theme === "dark"
            ? "border-b border-white border-opacity-70"
            : "border-b border-black-900 border-opacity-70"
        }`}
      >
        <Dropdown
          trigger={["click"]}
          align={{ offset: [0, -5] }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          overlay={
            <div
              className={` w-[100vh] ${
                theme === "dark" ? "text-white " : "text-black-900"
              }`}
            >
              <div
                className={` rounded-lg ${
                  theme === "dark"
                    ? "bg-black-1100 border-white "
                    : "bg-grey-500 border-black-1100"
                }`}
              >
                <div className="text-sm font-bold px-2 py-2">
                  Selected Models :
                </div>
                <ReactDragListView {...dragProps}>
                  {selectedAiModel &&
                    selectedAiModel.length > 0 &&
                    selectedAiModel.map((model) => (
                      <div className="flex flex-row gap-3  px-2 pb-1.5 cursor-pointer">
                        <MdDragIndicator className="mt-0.5" size={16} />
                        <div className="text-xs   font-semibold opacity-80">
                          {model}
                        </div>
                      </div>
                    ))}
                </ReactDragListView>
                <div className="grid grid-cols-4 gap-5">
                  {aiModels.map((model) => {
                    return (
                      <div className="p-2 ">
                        <div className="text-base font-semibold pb-2.5">
                          {model.model}
                        </div>
                        <div className="border-t border-white border-opacity-50 pt-2.5">
                          {model.list &&
                            model.list.length > 0 &&
                            model.list.map((el) => (
                              <div className="flex flex-row gap-2    px-1.5 pt-2">
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    if (e.target.checked) {
                                      setSelectedAiModel([
                                        ...selectedAiModel,
                                        el.value,
                                      ]);
                                    } else {
                                      setSelectedAiModel(
                                        selectedAiModel.filter(
                                          (model) => model !== el.value
                                        )
                                      );
                                    }
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  checked={selectedAiModel.includes(el.value)}
                                  class="w-4 h-4  mt-1 accent-blue-50 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-0 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <div className="pb-3 text-[13px] opacity-80 cursor-pointer">
                                  {el.label}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          }
        >
          <div className={`flex flex-row  pb-3 `}>
            <div
              className={` border h-12 px-4 cursor-pointer  py-2 rounded-lg flex flx-row gap-2 justify-center items-center ${
                theme === "dark"
                  ? "text-white border-white"
                  : "text-black-900 border-black-900 object-top rounded-3x"
              }`}
            >
              Select any model
              <IoMdArrowDropdown
                size={24}
                color={theme === "dark" ? "white" : "black"}
              />
            </div>
          </div>
        </Dropdown>
        <Dropdown
          trigger={["click"]}
          align={{ offset: [0, -5] }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          overlay={
            <div
              className={` w-fit ${
                theme === "dark" ? "text-white " : "text-black-900"
              }`}
            >
              <div
                className={` rounded-lg ${
                  theme === "dark"
                    ? "bg-black-1100 border-white "
                    : "bg-grey-500 border-black-1100"
                }`}
              >
                <div
                  className={`p-3 mb-1.5 cursor-pointer hover:opacity-100 hover:bg-black-200 w-[400px] break-all
                  ${responseMode === "sequential" ? "bg-black-200 " : ""}
                  `}
                >
                  <div
                    className={` text-sm  opacity-80  font-semibold 
                   

                    
                    `}
                    onClick={() => {
                      setResponseMode("sequential");
                    }}
                  >
                    Sequential Chaining{" "}
                  </div>
                  <div className="text-xs opacity-50 ">
                    Processes the conversation in order—AI #1’s output feeds
                    into AI #2, etc.—and the final AI compiles a single,
                    comprehensive answer.
                  </div>
                </div>

                <div
                  className={`p-3 mb-1.5 cursor-pointer hover:opacity-100 hover:bg-black-200 w-[400px] break-all
                  ${responseMode === "parallel" ? "bg-black-200 " : ""}
                  `}
                >
                  {" "}
                  <div
                    className={` text-sm  opacity-80  font-semibold 
                                      

                                      

                    `}
                    onClick={() => {
                      setResponseMode("parallel");
                    }}
                  >
                    Multiple Parallel Responses
                  </div>
                  <div className="text-xs  opacity-50">
                    Each selected AI provides a separate response, shown in the
                    order the ai model selected.
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <div className={`flex flex-row  pb-3`}>
            <div
              className={` border h-12 px-4 cursor-pointer  py-2 rounded-lg flex flx-row gap-2 justify-center items-center ${
                theme === "dark"
                  ? "text-white border-white"
                  : "text-black-900 border-black-900 object-top rounded-3x"
              }`}
            >
              {responseMode === "sequential"
                ? " Sequential Chaining"
                : "Multiple Parallel Responses"}
              <IoMdArrowDropdown
                size={24}
                color={theme === "dark" ? "white" : "black"}
              />
            </div>
          </div>
        </Dropdown>
      </div>
      <div className={`grid grid-cols-3`}>
        <div className="col-span-2">
          <div className="w-full col-span-3 mt-2">
            {isChatMsgLoading ? (
              <ChatUiSkeleton theme={theme} />
            ) : (
              <div className="mt-8 mr-8 mb-2">
                <div
                  className={`overflow-y-auto hide-scrollbar relative  ${
                    uploadedFiles && uploadedFiles.length > 0
                      ? "h-[calc(100vh-56px-120px-48px)]"
                      : "h-[calc(100vh-56px-100px-48px)]"
                  } `}
                  onWheel={() => {
                    setIsScrolling(true);
                  }}
                  ref={scrollContainerRef}
                >
                  {chatMsgsToDisplay && chatMsgsToDisplay.length > 0 ? (
                    chatMsgsToDisplay.map((msg, index) => (
                      <ChatCard
                        message={msg}
                        regenerateHandler={regenerateHandler}
                        likeMsgHandler={() => likeMsgHandler(index)}
                        dislikeMsgHandler={() => dislikeMsgHandler(index)}
                        isVisible={index === chatMsgsToDisplay.length - 1}
                        isStreaming={isStreaming}
                        uploadedFiles={msg.uploadedFiles}
                        theme={theme}
                      />
                    ))
                  ) : (
                    <div className="text-white  h-full flex flex-row items-end pb-8"></div>
                  )}
                  {showLoading ? (
                    <ChatCard
                      message={{
                        role: "assistant",
                        content: loadingText,
                      }}
                      isVisible={false}
                      isStreaming={isStreaming}
                      theme={theme}
                    />
                  ) : latestStream && latestStream.content ? (
                    <ChatCard
                      message={{
                        role: "assitant",
                        content: latestStream.content,
                      }}
                      isVisible={false}
                      isStreaming={isStreaming}
                      theme={theme}
                    />
                  ) : null}
                </div>

                <div className="absolute bottom-[100px] left-[45%] z-50 ">
                  <div
                    className={`rounded-full  p-2 cursor-pointer w-8 h-8 hover:bg-black-50  ${
                      theme === "dark" ? "bg-grey-50" : "bg-neutral-50 "
                    }`}
                    onClick={scrollToBottom}
                  >
                    <MdArrowDownward
                      className="w-4 h-4 "
                      color={theme === "dark" ? "white" : "black"}
                    />
                  </div>
                </div>
                <div
                  className={`${
                    expanded ? "w-[50%]" : "w-[60%]"
                  } ml-2 absolute mr-10  bottom-4 ${
                    dragOver ? "drag-over" : ""
                  }`} // Apply visual feedback class
                  onDragOver={(e) => {
                    e.preventDefault();
                    setDragOver(true); // Add visual feedback if required
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault();
                    setDragOver(false); // Remove visual feedback
                  }}
                  onDrop={(e) => {
                    e.preventDefault();
                    setDragOver(false);

                    const files = Array.from(e.dataTransfer.files);
                    if (files.length > 0) {
                      uploadFileHandler(files);
                    }
                  }}
                >
                  {uploadedFiles && uploadedFiles.length > 0 && (
                    <div>{renderUploadedFile()}</div>
                  )}{" "}
                  <div className="w-full flex items-center justify-between">
                    <form
                      autoComplete="off"
                      action=""
                      onSubmit={(e) => e.preventDefault()}
                      className="w-full"
                    >
                      <textarea
                        ref={inputRef}
                        id="chat-input"
                        name="chat-input"
                        className={`px-9 py-3  w-full ${
                          uploadedFiles && uploadedFiles.length > 0
                            ? "rounded-b-lg"
                            : "rounded-lg"
                        } ${
                          theme === "dark"
                            ? "bg-transparent text-white border border-white border-opacity-50"
                            : "bg-transparent text-black  border  border-black-1100 border-opacity-50"
                        }  w-full focus:outline-none ${
                          rows === 1
                            ? "h-12 overflow-y-hidden"
                            : rows === 2
                            ? "h-16"
                            : rows === 3
                            ? "h-20"
                            : rows === 4
                            ? "h-[96px]"
                            : rows === 5
                            ? "h-[112px]"
                            : "h-[128px]"
                        }`}
                        value={inputValue}
                        onChange={(e) => {
                          const value = e.target.value;
                          setInputValue(value);
                        }}
                        onKeyDown={(e) => {
                          if (e.shiftKey && e.key === "Enter") {
                            if (rows < 6) {
                              setRows(rows + 1);
                            }
                            setInputValue(inputValue);
                          } else if (e.key === "Enter" && inputValue) {
                            e.preventDefault();

                            handleKeyPress();
                            setRows(1);
                          }
                        }}
                        placeholder="Message GG AI"
                      />
                      <div>
                        <div
                          className={`absolute left-0 w-6 h-4  text-white ml-2 mr-3 cursor-pointer bottom-8`}
                          onClick={() => {
                            if (!isUploadFileLoading) {
                              fileInputRef.current.click();
                            }
                          }}
                        >
                          {isUploadFileLoading ? (
                            <Loader className="w-4 h-4" />
                          ) : (
                            <IoIosAttach
                              color={theme === "dark" ? "white" : "black"}
                              size={24}
                              className="mt-1"
                            />
                          )}
                        </div>
                        <input
                          ref={fileInputRef}
                          type="file"
                          multiple={true}
                          onChange={(event) => {
                            uploadFileHandler(event.target.files);
                          }}
                          style={{ display: "none" }}
                        />

                        <div
                          class={`absolute bottom-4  w-8 h-8 flex items-center  cursor-pointer ${
                            expanded
                              ? isStreaming
                                ? "left-[92%]"
                                : "left-[89%]"
                              : "left-[91%]"
                          }`}
                        >
                          {isStreaming ? (
                            <div onClick={stopStreamingHandler}>
                              <img
                                alt="Stop Stream"
                                src={stopStreamIcon}
                                className=" mr-1 pb-1"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-row">
                              <div className="h-8 mr-2">
                                {isListening ? (
                                  <Loader />
                                ) : (
                                  <AudioRecorder
                                    onRecordingComplete={(audioBlob) => {
                                      uploadAudio(audioBlob);
                                    }}
                                    audioTrackConstraints={{
                                      noiseSuppression: true,
                                      echoCancellation: true,
                                    }}
                                  />
                                )}
                              </div>

                              <div
                                className="rounded-md  bg-blue-50 p-2 cursor-poiner"
                                onClick={(e) => {
                                  e.stopPropagation();

                                  if (inputValue) {
                                    e.preventDefault();

                                    handleKeyPress();
                                  }
                                }}
                              >
                                <LuSend className="w-4 h-4 text-white" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-1 px-2">
          <div className="h-[77vh] overflow-y-auto hide-scrollbar border-b pb-4 border-white border-opacity-70 ">
            <div>
              <div className="flex flex-row justify-between mt-4">
                <div
                  className={`text-[17px] font-bold ${
                    theme === "dark" ? "text-white" : "text-black-100"
                  } text-opacity-80`}
                >
                  Upload Files
                </div>

                {isFilesExpanded ? (
                  <BiChevronUpCircle
                    size={18}
                    color={theme === "dark" ? "white" : "black"}
                    className="cursor-pointer"
                    onClick={() => {
                      setFilesExpanded(false);
                    }}
                  />
                ) : (
                  <MdOutlineExpandCircleDown
                    size={18}
                    color={theme === "dark" ? "white" : "black"}
                    className="cursor-pointer"
                    onClick={() => {
                      setFilesExpanded(true);
                    }}
                  />
                )}
              </div>
              <div
                className={`text-xs mb-4 ${
                  theme === "dark" ? "text-white" : "text-black-100"
                } text-opacity-50`}
              >
                ({files && files.length > 0 ? files.length : 0} files)
              </div>
            </div>
            {isFilesExpanded && (
              <div className="">
                {files && files.length > 0 ? (
                  files.map((file) => (
                    <div className="flex flex-row justify-between my-2">
                      <div className="flex flex-row gap-2">
                        <FaFileAlt
                          className=" w-6 h-6 opacity-80"
                          color={theme === "dark" ? "white" : "black"}
                        />

                        <div
                          className={`${
                            theme === "dark" ? "text-white" : "text-black-100"
                          } opacity-70 text-sm mb-2 `}
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            WebkitLineClamp: 1,
                            wordBreak: "break-all",
                          }}
                        >
                          {file.file_name}
                        </div>
                      </div>
                      <div className="flex flex-row gap-2">
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            navigate(`/chat/${file.chat_id}`);
                          }}
                        >
                          <IoEyeOutline
                            className=" w-6 h-6 opacity-80"
                            color={theme === "dark" ? "white" : "black"}
                          />
                        </div>
                        <a href={file.file_url}>
                          <GoDownload
                            className=" w-6 h-6 opacity-80"
                            color={theme === "dark" ? "white" : "black"}
                          />
                        </a>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex flex-row justify-left gap-2 mb-4">
                    <ImFilesEmpty
                      color={theme === "dark" ? "white" : "black"}
                      className="opacity-70 w-6 h-6 pr-2"
                    />
                    <div
                      className={`${
                        theme === "dark" ? "text-white" : "text-black-100"
                      } opacity-70 `}
                    >
                      No Files Found
                    </div>
                  </div>
                )}
              </div>
            )}
            <div>
              <div className="flex flex-row justify-between">
                <div
                  className={`text-[17px] font-bold opacity-80 ${
                    theme === "dark" ? "text-white" : "text-black-100"
                  }`}
                >
                  Chat History
                </div>
                {isChatHistoryExpanded ? (
                  <BiChevronUpCircle
                    size={18}
                    color={theme === "dark" ? "white" : "black"}
                    className="cursor-pointer"
                    onClick={() => {
                      setChatHistoryExpanded(false);
                    }}
                  />
                ) : (
                  <MdOutlineExpandCircleDown
                    size={18}
                    color={theme === "dark" ? "white" : "black"}
                    className="cursor-pointer"
                    onClick={() => {
                      setChatHistoryExpanded(true);
                    }}
                  />
                )}
              </div>
              <div
                className={`text-xs  ${
                  theme === "dark" ? "text-white" : "text-black-100"
                } text-opacity-50`}
              >
                (
                {chatHistory && chatHistory.length > 0 ? chatHistory.length : 0}{" "}
                chats)
              </div>
            </div>
            {isChatHistoryExpanded ? (
              chatHistoryLoading ? (
                <div className="">
                  {[...Array(5)].map((_, index) => (
                    <div
                      key={index}
                      className={`${
                        theme === "dark" ? "bg-black-100" : "bg-neutral-50"
                      } rounded-md p-2.5 h-10 mb-6 mt-3 ${
                        expanded ? "w-full" : "w-16"
                      } ${index !== 2 && "mb-1"}`}
                      style={{
                        transition: "width 0.5s ease-out",
                        transitionDelay: `${index * 0.1}s`,
                      }}
                    ></div>
                  ))}
                </div>
              ) : (
                <div className="">
                  {chatHistory && chatHistory.length > 0 ? (
                    <div className="flex flex-col">
                      {chatHistory.map((chat) => (
                        <div>
                          {selectedChat === chat.chat_id ? (
                            <Input
                              type="text"
                              value={updatedChatTitle}
                              ref={chatInputRef}
                              onChange={(e) =>
                                setUpdatedChatTitle(e.target.value)
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  saveUpdatedChatTitle();
                                }
                              }}
                            />
                          ) : (
                            <div
                              className={`flex flex-row justify-between items-center ${
                                theme === "dark"
                                  ? "hover:bg-black-200 "
                                  : "hover:bg-neutral-50"
                              } px-2 py-3 cursor-pointer rounded-lg`}
                              onClick={() => handleChatHistory(chat)}
                            >
                              <div
                                className={`${
                                  theme === "dark"
                                    ? "text-white"
                                    : "text-black-100"
                                } text-sm  opacity-80 flex flex-col justify-center  `}
                                style={{
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  WebkitLineClamp: 1,
                                  wordBreak: "break-all",
                                }}
                              >
                                {chat.title ? chat.title : "Untitled"}
                              </div>
                              <Dropdown
                                trigger={["click"]}
                                align={{ offset: [-120, 0] }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                overlay={
                                  <div
                                    className={`rounded-lg  w-[150%] ${
                                      theme === "dark"
                                        ? "bg-black-1100"
                                        : "bg-white"
                                    }`}
                                  >
                                    <div
                                      className={`flex flex-row p-3 cursor-pointer ${
                                        theme === "dark"
                                          ? "hover:bg-slate-400"
                                          : "hover:bg-neutral-50"
                                      } hover:rounded-t-lg`}
                                      onClick={() => {
                                        setShareChatModal(true);
                                        setSelectedChatId(chat.chat_id);
                                      }}
                                    >
                                      <IoShareSocial
                                        className="w-4 h-4 "
                                        color={
                                          theme === "dark" ? "white" : "black"
                                        }
                                      />

                                      <div
                                        className={`text-xs font-semibold ml-2 font-sans leading-4 spacing ${
                                          theme == "dark"
                                            ? "text-white"
                                            : "text-black"
                                        }`}
                                      >
                                        Share
                                      </div>
                                    </div>
                                    <div
                                      className={`flex flex-row p-3 cursor-pointer ${
                                        theme === "dark"
                                          ? "hover:bg-slate-400"
                                          : "hover:bg-neutral-50"
                                      } `}
                                      onClick={() => renameTitleHandler(chat)}
                                    >
                                      <MdDriveFileRenameOutline
                                        className="w-4 h-4 "
                                        color={
                                          theme === "dark" ? "white" : "black"
                                        }
                                      />
                                      <div
                                        className={`text-xs font-semibold ml-2 font-sans leading-4 spacing ${
                                          theme == "dark"
                                            ? "text-white"
                                            : "text-black"
                                        }`}
                                      >
                                        Rename
                                      </div>
                                    </div>

                                    <div
                                      className={`flex flex-row p-3 cursor-pointer ${
                                        theme === "dark"
                                          ? "hover:bg-slate-400"
                                          : "hover:bg-neutral-50"
                                      } hover:rounded-b-lg`}
                                      onClick={() => {
                                        deleteChatHistory(chat.chat_id);
                                      }}
                                    >
                                      <RiDeleteBin7Line
                                        className="w-4 h-4 "
                                        color={
                                          theme === "dark" ? "white" : "black"
                                        }
                                      />
                                      <div
                                        className={`text-xs font-semibold ml-2 font-sans leading-4 spacing ${
                                          theme == "dark"
                                            ? "text-white"
                                            : "text-black"
                                        }`}
                                      >
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                                }
                              >
                                <BiDotsHorizontalRounded
                                  color={theme === "dark" ? "white" : "black"}
                                  size={20}
                                  className="ml-2"
                                />
                              </Dropdown>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col mb-5">
                      <div
                        className={`${
                          theme === "dark" ? "text-white" : "text-black-100"
                        } text-xs opacity-80`}
                      >
                        No Messages . Please start a conversation to have chat
                        history
                      </div>
                    </div>
                  )}
                </div>
              )
            ) : null}
          </div>
          <div
            className={` w-full my-4   pr-6  mr-6 bg-blue-50 rounded-lg mb-2 text-white border-opacity-50  flex flex-row justify-center items-center h-10
              ${
                isStreaming
                  ? "cursor-not-allowed opacity-50 "
                  : theme === "dark"
                  ? "cursor-pointer  hover:bg-opacity-80"
                  : "cursor-pointer  hover:bg-opacity-80"
              }
              `}
            onClick={newChatHandler}
          >
            New Chat
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserChat;
