import { SiAnthropic, SiGooglegemini } from "react-icons/si";
import xai from "../assets/xai.png";
import { AiOutlineOpenAI } from "react-icons/ai";

const loadingMsgs = [
  "Loading data",
  "Analyzing assets",
  "Retrieving info",
  "Scanning stats",
];

const helpPrompts = [
  {
    title: "Make me a personal web page",
    helperText: "after asking me 3 questions",
    description:
      "Create a personal webpage for me, all in a single file. Ask me 3 questions first on whatever you need to know.",
  },
  {
    title: "Explain nostalgia",
    helperText: "to a kindergartener",
    description:
      "Can you explain the concept of nostalgia to a kindergartener? ",
  },
  {
    title: "Write a thank you note ",
    helperText: "to my interviewer",
    description:
      "Write 2-3 sentences to thank my interviewer, reiterating my excitement for the job opportunity while keeping it cool. Don't make it too formal.        ",
  },
  {
    title: "Tell me a fun fact",
    helperText: "Roman Empire",
    description: "Tell me a random fun fact about the Roman Empire",
  },
  {
    title: "Design a portfolio",
    helperText: "Collect basic details",
    description:
      "Build a portfolio website for me. Ask for details like my skills, projects, and experience.",
  },
  {
    title: "Create a recipe",
    helperText: "using ingredients from my kitchen",
    description:
      "Could you ask me to list five ingredients from my pantry, and then help me invent a new recipe using them?        ",
  },

  {
    title: "Create a workout routine",
    helperText: "Ask about fitness goals and equipment",
    description:
      "Design a personalized workout routine. Ask me about my fitness goals, available equipment, and preferred workout types.",
  },
  {
    title: "Design a resume",
    helperText: "Ask for personal and professional details",
    description:
      "Generate a resume. Ask me about my experience, skills, and contact details before creating the document.",
  },
  {
    title: "Make a geography quiz",
    helperText: "Ask about difficulty level and regions",
    description:
      "Design a geography quiz. Ask me what difficulty level I want and which regions or countries to include.",
  },
  {
    title: "Set up a travel journal",
    helperText: "Ask about destinations and travel style",
    description:
      "Create a travel journal webpage. Ask me about my past travel destinations, style of travel, and photos to include.",
  },
  {
    title: "Build a historical event analysis",
    helperText: "Ask for the event and sources of interest",
    description:
      "Create an analysis of a historical event. Ask me which event to cover and which sources or perspectives to include.",
  },
  {
    title: "Design a world map",
    helperText: "Ask for countries or regions to highlight",
    description:
      "Build a custom world map. Ask me which countries or regions to focus on and what details should be highlighted.",
  },
  {
    title: "Create a nutrition tracker",
    helperText: "Ask for dietary habits and goals",
    description:
      "Set up a nutrition tracking app. Ask about my dietary habits, health goals, and what kind of foods to track.",
  },
  {
    title: "Design a museum tour",
    helperText: "Ask for interests and historical periods",
    description:
      "Create a virtual museum tour. Ask me about my interests, favorite historical periods, and what museums I want to explore.",
  },
  {
    title: "Make a hiking guide",
    helperText: "Ask for difficulty and location preferences",
    description:
      "Design a hiking guide for me. Ask about my preferred hiking difficulty, locations, and what gear I have available.",
  },
  {
    title: "Set up a cultural food tour",
    helperText: "Ask for preferred cultures and regions",
    description:
      "Create an itinerary for a cultural food tour. Ask me which cultures or regions I want to explore and what types of food I prefer.",
  },
  {
    title: "Create a fitness progress tracker",
    helperText: "Ask for workout types and goals",
    description:
      "Build a fitness progress tracker. Ask about my workout types, fitness goals, and how often I want to track progress.",
  },
  {
    title: "Create a mental health journal",
    helperText: "Ask for daily mood and reflections",
    description:
      "Set up a mental health journal for me. Ask about my daily mood, reflections, and any specific issues I want to track.",
  },
  {
    title: "Build a language learning plan",
    helperText: "Ask for target language and learning style",
    description:
      "Create a language learning plan. Ask which language I want to learn, my current level, and preferred learning methods.",
  },
  {
    title: "Design a hobby tracker",
    helperText: "Ask for hobbies and frequency",
    description:
      "Set up a hobby tracker for me. Ask about my hobbies, how often I engage in them, and what I want to track.",
  },
  {
    title: "Create a reading list",
    helperText: "Ask for favorite genres and authors",
    description:
      "Build a personalized reading list. Ask about my favorite genres, authors, and how many books I plan to read.",
  },
  {
    title: "Develop a study schedule",
    helperText: "Ask for subjects and study time",
    description:
      "Create a study schedule for me. Ask about the subjects I’m studying, how much time I have, and my learning goals.",
  },
  {
    title: "Set up a gardening plan",
    helperText: "Ask for plant types and garden size",
    description:
      "Build a gardening plan. Ask what type of plants I want to grow, my garden size, and any specific goals.",
  },
  {
    title: "Design an online course",
    helperText: "Ask for course topics and structure",
    description:
      "Create an online course for me. Ask about the topic, target audience, and structure of the course.",
  },
  {
    title: "Make a sleep schedule",
    helperText: "Ask for current sleep habits and goals",
    description:
      "Design a custom sleep schedule for me. Ask about my current sleep habits, issues, and desired sleep improvements.",
  },
  {
    title: "Build a career development plan",
    helperText: "Ask for career goals and timeline",
    description:
      "Create a career development plan. Ask about my career goals, skills I need to learn, and a timeline to achieve them.",
  },
  {
    title: "Set up a personal budget",
    helperText: "Ask for income, expenses, and goals",
    description:
      "Create a personalized budget plan. Ask me about my income, expenses, and financial goals.",
  },
  {
    title: "Create a self-care routine",
    helperText: "Ask for favorite activities and time available",
    description:
      "Design a self-care routine. Ask me about my favorite activities, available time, and how I prefer to relax.",
  },
  {
    title: "Design a photography challenge",
    helperText: "Ask for photography interests and level",
    description:
      "Create a personalized photography challenge. Ask me about my photography interests, skill level, and what equipment I have.",
  },
  {
    title: "Build a custom yoga routine",
    helperText: "Ask for flexibility and strength goals",
    description:
      "Create a yoga routine for me. Ask about my flexibility and strength goals, current level, and available time.",
  },
];

const industryOptions = [
  { label: "Technology", value: "technology" },
  { label: "Healthcare", value: "healthcare" },
  { label: "Education", value: "education" },
  { label: "Finance", value: "finance" },
  { label: "Manufacturing", value: "manufacturing" },
  { label: "Retail", value: "retail" },
  { label: "Hospitality", value: "hospitality" },
  { label: "Real Estate", value: "real_estate" },
  { label: "Transportation", value: "transportation" },
  { label: "Media & Entertainment", value: "media_entertainment" },
  { label: "Other", value: "other" },
];

const companySizes = [
  { label: "1-10", value: "1-10" },
  { label: "11-50", value: "11-50" },
  { label: "51-200", value: "51-200" },
  { label: "201-500", value: "201-500" },
  { label: "500 +", value: "500+" },
];

const plans = [
  {
    title: "Basic Tier",
    price: "$25 / month",
    features: [
      "2,000,000 tokens per month ($10 per 1m after)",
      "512MB storage ($1 per 1GB after)",
      "Early access to new features",
      "Basic analytics and insights",
      "Standard support",
    ],
    value: "starter",
  },
  {
    title: "Premium Tier",
    price: "$50 / month",
    features: [
      "6,500,000 tokens per month ($10 per 1m after)",
      "1GB storage ($1 per 1GB after)",
      "Priority access to new features",
      "Advanced analytics and insights",
      "7-day free trial (credit card required)",
      "24/7 premium support",
    ],
    value: "premium",
  },
  {
    title: "Custom  Tier",
    price: "Contact Us",
    features: [
      "Flexible Tokens & Storage",
      "Priority access to new features",
      "Advanced Compliance (HIPAA, finance, etc.)",
      "24/7 premium support",
      "Onboarding and training sessions",
      "Access to exclusive webinars and events",
    ],
    value: "custom",
  },
];

const faqs = [
  {
    question: "Can I try GG AI before committing to a paid plan?",
    answer:
      "Yes, we offer a free plan that ends on March 31, 2025. This plan is a great way to test the platform and see if it meets your needs before upgrading to a paid plan.",
  },

  {
    question:
      "Do you offer discounts for non-profit organizations or educational institutions?",
    answer:
      "Yes, we have a new year sale for new accounts created before March 31st. You'll get to enjoy free access until the promotion ends on March 31st. After that you will be charged for the selected plan.",
  },
  {
    question: "Do you offer a free trial for any of the plans?",
    answer:
      "Yes, every plan comes with a 1 week free trial to test out the platform.",
  },
  {
    question: "Can I upgrade or downgrade my plan at any time?",
    answer:
      "Yes, you will have the option to cancel or change your plan at any time.",
  },
];

const aiModelDetails = [
  {
    label: "GPT 4o",
    value: "gpt-4o",
  },
  {
    label: "GPT 4o-mini",
    value: "gpt-4o-mini",
  },
  {
    label: "GPT o1-preview",
    value: "o1-preview",
  },
  {
    label: "GPT o1-mini",
    value: "o1-mini",
  },
  {
    label: "GPT 4 Turbo",
    value: "gpt-4-turbo",
  },
  {
    label: "GPT 3-turbo	",
    value: "gpt-3.5-turbo",
  },
  {
    label: "Gemini Flash 2.0",
    value: "gemini/gemini-2.0-flash",
  },
  {
    label: "Gemini Pro 1.5 ",
    value: "gemini/gemini-1.5-pro-latest",
  },
  {
    label: "Gemini 1.5 Flash",
    value: "gemini/gemini-1.5-flash",
  },
  {
    label: "claude-3-5-sonnet",
    value: "claude-3-5-sonnet-20240620",
  },
  {
    label: "claude-3-haiku",
    value: "claude-3-haiku-20240307",
  },
  {
    label: "claude-3-opus",
    value: "claude-3-opus-20240229",
  },
  {
    label: "claude-3-sonnet",
    value: "claude-3-sonnet-20240229",
  },
  {
    label: "claude-2.1",
    value: "claude-2.1",
  },

  {
    label: "claude-2",
    value: "claude-2",
  },
  {
    label: "Grok 2 Vision",
    value: "grok-2-vision-1212",
  },
  {
    label: "Grok Vision Beta",
    value: "grok-vision-beta",
  },
  {
    label: "Grok Beta",
    value: "grok-beta",
  },
];
const aiModels = [
  {
    model: "OPENAI",
    icon: <AiOutlineOpenAI size={24} color="#00A67E" />,
    list: [
      {
        label: "GPT 4o",
        value: "gpt-4o",
      },
      {
        label: "GPT 4o-mini",
        value: "gpt-4o-mini",
      },
      {
        label: "GPT o1-preview",
        value: "o1-preview",
      },
      {
        label: "GPT o1-mini",
        value: "o1-mini",
      },
      {
        label: "GPT 4 Turbo",
        value: "gpt-4-turbo",
      },
      {
        label: "GPT 3-turbo	",
        value: "gpt-3.5-turbo",
      },
    ],
  },
  {
    model: "Google",
    icon: <SiGooglegemini size={24} color="#00A6fE" />,
    list: [
      {
        label: "Gemini Flash 2.0",
        value: "gemini/gemini-2.0-flash",
      },
      {
        label: "Gemini Pro 1.5 ",
        value: "gemini/gemini-1.5-pro-latest",
      },
      {
        label: "Gemini 1.5 Flash",
        value: "gemini/gemini-1.5-flash",
      },
    ],
  },
  {
    model: "X",
    icon: <img src={xai} className="w-6 h-6" />,
    list: [
      {
        label: "Grok 2 Vision",
        value: "grok-2-vision-1212",
      },
      {
        label: "Grok Vision Beta",
        value: "grok-vision-beta",
      },
      {
        label: "Grok Beta",
        value: "grok-beta",
      },
    ],
  },
  {
    model: "Anthropic",
    icon: <SiAnthropic size={24} color="#00A6fE" />,
    list: [
      {
        label: "claude-3-5-sonnet",
        value: "claude-3-5-sonnet-20240620",
      },
      {
        label: "claude-3-haiku",
        value: "claude-3-haiku-20240307",
      },
      {
        label: "claude-3-opus",
        value: "claude-3-opus-20240229",
      },
      {
        label: "claude-3-sonnet",
        value: "claude-3-sonnet-20240229",
      },
      {
        label: "claude-2.1",
        value: "claude-2.1",
      },

      {
        label: "claude-2",
        value: "claude-2",
      },
    ],
  },
];

const supportedLanguages = [
  { label: "English (en)", value: "english" },
  { label: "Spanish (es)", value: "spanish" },
  { label: "French (fr)", value: "french" },
  { label: "German (de)", value: "german" },
  { label: "Chinese (Simplified) (zh)", value: "chinese" },
  { label: "Chinese (Traditional) (zh-tw)", value: "chinese" },
  { label: "Japanese (ja)", value: "japanese" },
  { label: "Korean (ko)", value: "korean" },
  { label: "Hindi (hi)", value: "hindi" },
  { label: "Arabic (ar)", value: "arabic" },
  { label: "Portuguese (pt)", value: "portuguese" },
  { label: "Russian (ru)", value: "russian" },
  { label: "Italian (it)", value: "italian" },
  { label: "Dutch (nl)", value: "dutch" },
  { label: "Turkish (tr)", value: "turkish" },
  { label: "Polish (pl)", value: "polish" },
  { label: "Swedish (sv)", value: "swedish" },
  { label: "Danish (da)", value: "danish" },
  { label: "Norwegian (no)", value: "norwegian" },
  { label: "Finnish (fi)", value: "finnish" },
  { label: "Greek (el)", value: "greek" },
  { label: "Thai (th)", value: "thai" },
  { label: "Vietnamese (vi)", value: "vietnamese" },
  { label: "Indonesian (id)", value: "indonesian" },
  { label: "Czech (cs)", value: "czech" },
  { label: "Hungarian (hu)", value: "hungarian" },
  { label: "Hebrew (he)", value: "hebrew" },
  { label: "Ukrainian (uk)", value: "ukrainian" },
  { label: "Malay (ms)", value: "malay" },
  { label: "Romanian (ro)", value: "romanian" },
  { label: "Bulgarian (bg)", value: "bulgarian" },
  { label: "Slovak (sk)", value: "slovak" },
  { label: "Croatian (hr)", value: "croatian" },
  { label: "Serbian (sr)", value: "serbian" },
  { label: "Filipino (tl)", value: "filipino" },
];

export {
  loadingMsgs,
  helpPrompts,
  industryOptions,
  companySizes,
  plans,
  faqs,
  aiModels,
  supportedLanguages,
  aiModelDetails,
};
