import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userType = queryParams.get("user-type") || "individual";

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-black-100 text-white px-6">
      {userType === "workspace" ? (
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg max-w-md text-center">
          <h2 className="text-2xl font-bold mb-4">Payment Successful!</h2>
          <p className="text-gray-300 mb-6">
            Thank you for your payment. Your transaction has been successfully
            processed. You’ll be redirected shortly.
          </p>
          <p className="text-gray-300 mb-6 font-bold">
            We have sent access request to all the emails.
          </p>
          <button
            onClick={() => navigate("/")}
            className="bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-2 px-4 rounded transition duration-200"
          >
            Go to Home Page Now
          </button>
          <p className="text-gray-500 mt-4 text-sm">
            You’ll be automatically redirected to home page in a few seconds.
          </p>
        </div>
      ) : (
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg max-w-md text-center">
          <h2 className="text-2xl font-bold mb-4">Payment Successful!</h2>
          <p className="text-gray-300 mb-6">
            Thank you for your payment. Your transaction has been successfully
            processed. You’ll be redirected to the login shortly.
          </p>

          <button
            onClick={() => navigate("/")}
            className="bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-2 px-4 rounded transition duration-200"
          >
            Go to Home Page Now
          </button>
          <p className="text-gray-500 mt-4 text-sm">
            You’ll be automatically redirected to home page in a few seconds.
          </p>
        </div>
      )}
    </div>
  );
};

export default SuccessPage;
