import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FaBackward,
  FaBalanceScale,
  FaBars,
  FaFileExport,
  FaThemeco,
  FaTimes,
  FaUser,
} from "react-icons/fa";
import UserBillingInfo from "./UserBillingInfo";
import axios from "axios";
import { getAPIBaseUrl } from "../../utility/constants";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import EnterpriseDashboard from "../EnterpriseDashboard";
import { toast } from "react-toastify";
import { googleLogout } from "@react-oauth/google";
import { setUserDetails } from "../../redux/actions/authActions";
import { IoClose, IoCloseCircle } from "react-icons/io5";
import { CustomModal } from "../../components/CustomModal";
import PhoneInput from "react-phone-number-input";
import Loader from "../../components/Loader";
import Select from "react-select";
import { supportedLanguages } from "../../utils";
import { RiLockPasswordFill } from "react-icons/ri";
import {
  MdAccessTime,
  MdOutlineWorkspacePremium,
  MdSecurity,
} from "react-icons/md";
import UpdatePassword from "./UpdatePassword";
import Appearance from "./Appearance";
import General from "./General";
import Session from "./Session";
import Security from "./Security";
import {
  isMobile,
  isTablet,
  isDesktop,
  browserName,
  osName,
  deviceType,
} from "react-device-detect";
import ExportChat from "./ExportChat";
import { loadStripe } from "@stripe/stripe-js";
import Workspace from "./Workspace";

// const stripePromise = loadStripe(
//   "pk_test_51Q0ojDHG7saj5CIN9MTIVlUg8CT7OnuOvRNN7Deo1i7uOLbVlr2HTDZoyCsPxfv7mLlK4ko6dKiB1wRYr3cnL1yF00px5khdl9"
// );

const stripePromise = loadStripe(
  "pk_live_51Q0ojDHG7saj5CINiX3EaCv8ORQTIb9nyOaQvmQ3R0vs7LkuuQtqfH13vzlFsim7Gk0yAiJMbb4CK8GtHDPSlJ0300fE1xnbYY"
);
const UserSettings = ({ setPage }) => {
  const [selectedTab, setSelectedTab] = useState("general");
  const [billingInfo, setBillingInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [isMfaModalOpen, setMfaModalOpen] = useState(false);
  const [mfaMethod, setMFaMethod] = useState("");
  const [mfaScreen, setMfaScreen] = useState("select-method");
  const [qrCode, setQrCode] = useState("");
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showDisableMfaModal, setDisableMfaModal] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "english"
  );
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  const navigate = useNavigate();
  const storeDispatch = useDispatch();

  console.log("browserName>>", browserName + "...", osName);
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      border: theme === "dark" ? "1px solid white" : "1px solid #232627",
      color: "white",
      "&:hover": {
        borderColor: "#3B364B",
      },
      minHeight: "44px",
      fontSize: 14,
      borderRadius: 8,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme === "dark" ? "white" : "black",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6b7280",
      fontSize: 14,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme === "dark" ? "#151718" : "#e4e4e7",
    }),
    option: (provided, state) => ({
      ...provided,
      color: theme === "dark" ? "white" : "black",
      backgroundColor: theme === "dark" ? "#151718" : "#e4e4e7",
      "&:hover": {
        background: "rgba(255, 255, 255, 0.7)", // Updated to set background opacity to 70%
      },
      cursor: "pointer",
    }),
  };

  const { currentUser, userType } = useSelector((state) => state.auth);

  const addTokenHandler = async (selectedAddon) => {
    const requestData = {
      email: currentUser?.email,
      addon_plan: selectedAddon,
    };
    try {
      const { data } = await axios.post(
        `${getAPIBaseUrl()}/create-addon-checkout-session`,
        requestData
      );

      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({
        sessionId: data.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to initiate checkout");
    }
  };
  const logoutUser = async () => {
    storeDispatch(setUserDetails({}));
    googleLogout();
    navigate("/login");

    const data = {
      email: currentUser?.email,
    };
    const response = await axios.post(
      `${getAPIBaseUrl()}/revoke-all-session`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const sendOtpHandler = async () => {
    setOtpLoading(true);
    const data = {
      email: currentUser?.email,
      phoneNumber: phoneNumber,
    };
    try {
      const response = await axios.post(`${getAPIBaseUrl()}/send-otp`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setSuccessMessage("OTP has been sent successfully!");
    } catch (error) {
      toast.error("Something went wrong ! Please try again !");
    } finally {
      setOtpLoading(false);
    }
  };
  const disableMfaHandler = async () => {
    const data = {
      email: currentUser?.email,
      otp: otp,
    };
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/disable-mfa`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("MFA is disabled successfully !");
      getUserInfo();
      setDisableMfaModal(false);
    } catch (error) {
      toast.error("Unable to disable MFA! Try again");
    }
  };

  const verifyPhoneOtpHandler = async () => {
    setLoading(true);
    const data = {
      email: currentUser?.email,
      otp: otp,
    };
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/verify-phone-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setMfaModalOpen(false);
      setMFaMethod("");
      setMfaScreen("select-method");
      setOtp("");
      toast.success("MFA is enabled successfully !");
      getUserInfo();
    } catch (error) {
      toast.error("Invalid code !");
    } finally {
      setLoading(false);
    }
  };

  const verifyOtpHandler = async () => {
    setLoading(true);

    const data = {
      email: currentUser?.email,
      otp: otp,
    };
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/verify-authenticator-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setMfaModalOpen(false);
      setQrCode("");
      setMFaMethod("");
      setMfaScreen("select-method");
      setOtp("");
      toast.success("MFA is enabled successfully !");
      getUserInfo();
    } catch (error) {
      toast.error("Invalid code !");
    } finally {
      setLoading(false);
    }
  };

  const enableMfaHandler = async () => {
    if (mfaMethod === "authenticator") {
      const data = {
        email: currentUser?.email,
      };
      try {
        const response = await axios.post(
          `${getAPIBaseUrl()}/generate-qr`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setMfaScreen("verify-otp");
        setQrCode(response?.data?.qr_code);

        console.log("response>>>", response);
      } catch (error) {
        setQrCode("");

        toast.error("Unable top process! Please try again!");
      }
    } else {
      setMfaScreen("verify-otp");
    }
  };

  console.log("user>>", userInfo);

  const deleteUserHandler = async () => {
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-user?email=${currentUser.email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("User deleted successfully!");
      setShowModal(false);

      logoutUser();
    } catch (error) {
      toast.error("Failed to delete user.");
    }
  };

  const deleteAllChatHandler = async () => {
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-chat-history?email=${currentUser.email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Chats  deleted successfully!");
      setShowChatModal(false);
    } catch (error) {
      toast.error("Failed to delete chats.");
    }
  };

  const getUserInfo = async () => {
    setLoading(true);
    try {
      const email = currentUser && currentUser.email ? currentUser.email : "";
      const user_type = userType;
      const enterprise_id =
        currentUser && currentUser.enterprise_id
          ? currentUser.enterprise_id
          : "";

      if (email) {
        const response = await axios.get(
          `${getAPIBaseUrl()}/get-user-info?user_email=${email}&user_type=${user_type}&enterprise_id=${enterprise_id}`
        );

        if (response && response.data && response.data.user_info) {
          setUserInfo(response.data.user_info);
        }
      } else {
        setUserInfo({});
      }
    } catch (error) {
      console.error("Error fetching billing info:", error);
      setUserInfo({});
    } finally {
      setLoading(false);
    }
  };

  const getUserBillingInfo = async () => {
    setLoading(true);
    try {
      const email =
        userType === "individual"
          ? currentUser.email
          : currentUser && currentUser.user && currentUser.user.email
          ? currentUser.user.email
          : "";
      const user_type = userType;
      const enterprise_id =
        userType === "enterprise"
          ? currentUser &&
            currentUser.enterprise &&
            currentUser.enterprise.enterprise_id
            ? currentUser.enterprise.enterprise_id
            : ""
          : "";

      if (email) {
        const response = await axios.get(
          `${getAPIBaseUrl()}/get-billing-info?user_email=${email}&user_type=${user_type}&enterprise_id=${enterprise_id}`
        );

        if (response && response.data) {
          setBillingInfo(response.data);
        }
      } else {
        setBillingInfo({});
      }
    } catch (error) {
      console.error("Error fetching billing info:", error);
      setBillingInfo({});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserInfo();
    getUserBillingInfo();
  }, []);

  useEffect(() => {
    if (
      currentUser &&
      Object.keys(currentUser).length > 0 &&
      currentUser.phone_number
    ) {
      setPhoneNumber(currentUser?.phone_number);
    }
  }, [currentUser]);

  const progressPercentage = Math.min(
    (userInfo.tokens_used / userInfo.tokens_limit) * 100,
    100
  );

  return (
      <div className="bg-chat-bg text-text-dark flex md:flex-row flex-col p-4 max-w-[720px] rounded-3xl">
        <div className="p-2 flex md:flex-col md:items-start flex-row gap-4 w-full flex-wrap">
          <div
            className={`flex flex-row gap-2 justify-center items-center cursor-pointer
            ${selectedTab === "general" ? "opacity-100" : "opacity-60"}
            `}
            onClick={() => {
              setSelectedTab("general");
            }}
          >
            <FaUser className="shrink-0" size={15} />
            <div className="text-[15px] font-sans text-nowrap">General</div>
          </div>
          <div
            className={`flex flex-row gap-2 justify-center items-center  cursor-pointer
            ${selectedTab === "password" ? "opacity-100" : "opacity-60"}
            `}
            onClick={() => {
              setSelectedTab("password");
            }}
          >
            <RiLockPasswordFill className="shrink-0" size={15} />
            <div className="text-[15px] font-sans text-nowrap">Password</div>
          </div>
          <div
            className={`flex flex-row gap-2 justify-center items-center  cursor-pointer
            ${selectedTab === "exportChat" ? "opacity-100" : "opacity-60"}
            `}
            onClick={() => {
              setSelectedTab("exportChat");
            }}
          >
            <FaFileExport className="shrink-0" size={15} />
            <div className="text-[15px] font-sans text-nowrap">Chat Export</div>
          </div>
          <div
            className={`flex flex-row gap-2 justify-center items-center  cursor-pointer
            ${selectedTab === "session" ? "opacity-100" : "opacity-60"}
            `}
            onClick={() => {
              setSelectedTab("session");
            }}
          >
            <MdAccessTime className="shrink-0" size={15} />
            <div className="text-[15px] font-sans text-nowrap">Sessions</div>
          </div>
          <div
            className={`flex flex-row gap-2 justify-center items-center  cursor-pointer
            ${selectedTab === "security" ? "opacity-100" : "opacity-60"}
            `}
            onClick={() => {
              setSelectedTab("security");
            }}
          >
            <MdSecurity className="shrink-0" size={15} />
            <div className="text-[15px] font-sans text-nowrap">Security</div>
          </div>
          <div
            className={`flex flex-row gap-2 justify-center items-center py-2 items-center cursor-pointer
            ${selectedTab === "appearance" ? "opacity-100" : "opacity-60"}
            `}
            onClick={() => {
              setSelectedTab("appearance");
            }}
          >
            <FaThemeco className="shrink-0" size={15} />
            <div className="text-[15px] font-sans text-nowrap">Appearance</div>
          </div>
          <div
            className={`flex flex-row gap-2 justify-center items-center py-2 items-center cursor-pointer
            ${selectedTab === "workspace" ? "opacity-100" : "opacity-60"}
            `}
            onClick={() => {
              setSelectedTab("workspace");
            }}
          >
            <MdOutlineWorkspacePremium className="shrink-0" size={15} />
            <div className="text-[15px] font-sans text-nowrap">Workspace</div>
          </div>

          <div
            className={`flex flex-row gap-2 justify-center items-center py-2 items-center cursor-pointer`}
            onClick={() => {
              setPage("privacy");
            }}
          >
            <FaBalanceScale className="shrink-0" size={15} />
            <div className="text-[15px] font-sans text-nowrap">Privacy Policy</div>
          </div>
          <div
            className={`flex flex-row gap-2 justify-center items-center py-2 items-center cursor-pointer`}
            onClick={() => {
              setPage("termsOfService");
            }}
          >
            <FaBalanceScale className="shrink-0" size={15} />
            <div className="text-[15px] font-sans text-nowrap">Terms of Service</div>
          </div>

          <div
            className="flex flex-row gap-2 justify-center items-center cursor-pointer text-red-200 hover:text-red-100"
            onClick={deleteUserHandler}
          >
            <IoClose className="mt-[3px]" size={18} />
            <div className="text-[15px] font-sans text-nowrap">Delete Account</div>
          </div>
        </div>

        <div className="grow">
          {selectedTab === "general" ? (
            <General
              userInfo={userInfo}
              theme={theme}
              addTokenHandler={addTokenHandler}
            />
          ) : selectedTab === "password" ? (
            <UpdatePassword />
          ) : selectedTab === "appearance" ? (
            <Appearance
              theme={theme}
              updateTheme={(theme) => {
                console.log("theme>>", theme);
                setTheme(theme);
                localStorage.setItem("theme", theme);
                document.documentElement.setAttribute("data-theme", theme);
                window.location.reload();
              }}
            />
          ) : selectedTab === "session" ? (
            <Session />
          ) : selectedTab === "security" ? (
            <Security userInfo={userInfo} getUserInfo={getUserInfo} />
          ) : selectedTab === "exportChat" ? (
            <ExportChat />
          ) : selectedTab === "workspace" ? (
            <Workspace userInfo={userInfo} />
          ) : null}
        </div>
      </div>
  );
};

export default UserSettings;
