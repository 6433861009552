import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "../../assets/google-icon.png";
import microsoftIcon from "../../assets/microsoft.png";
import { useGoogleLogin } from "@react-oauth/google";
import {
  setUserActionType,
  setUserDetails,
} from "../../redux/actions/authActions";
import { toast } from "react-toastify";
import { getAPIBaseUrl } from "../../utility/constants";
import axios from "axios";
import { useDispatch } from "react-redux";
import Loader from "../../components/Loader";
import { CustomModal } from "../../components/CustomModal";
import { FaTimes } from "react-icons/fa";
import { HiExternalLink } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";
import loginImage from "../../assets/login-bg.png";
import { browserName, osName } from "react-device-detect";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authUrl, setAuthUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light")
  );
  const [isForgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [isVerifyEmailModal, setVerifyEmailModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verifyCodeLoading, setVerifyCodeLoading] = useState(false);
  const [userType, setUserType] = useState("individual");
  const [enterpriseUniqueId, setEnterpriseUniqeuId] = useState("");

  const navigate = useNavigate();
  const storeDispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const handleSuccess = async (response) => {
    try {
      const token = response.access_token;

      const user_info = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (user_info && Object.keys(user_info).length > 0) {
        const { name, email, given_name, family_name } = user_info.data;

        const data = {
          name: name ? name : given_name + family_name,
          email,
          deviceType: `${browserName} on ${osName}`,
        };
        const response = await axios.post(
          `${getAPIBaseUrl()}/google-login`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response && response.data) {
          toast.success("Logged in successfully");
          storeDispatch(setUserActionType("individual"));
          storeDispatch(setUserDetails(response?.data?.user));
          if (response.data.code === "1") {
            navigate("/");
          } else if (response.data.code === "2") {
            navigate("/verify-mfa");
          } else {
            navigate("/billing");
          }
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.error, { autoClose: 5000 });
    }
  };
  const verifyUserHandler = async (e) => {
    e.stopPropagation();

    setVerifyCodeLoading(true);
    const data = {
      email: email,
      verification_code: verificationCode,
    };

    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/verify-user`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data && response.data.code === "2") {
        navigate("/verify-mfa");
      } else {
        checkPaymentStatus(email);
      }
    } catch (error) {
      toast.error("Invalid code ! Try again");
    } finally {
      setVerifyCodeLoading(false);
    }
  };

  const checkPaymentStatus = async (email) => {
    const data = {
      user_type: userType,
      email: email,
    };
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/check-user-payment-status`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response && response.data && response.data.is_payment) {
        navigate("/");
      } else {
        navigate("/billing");
      }
    } catch (error) {
      navigate("/billing");
    }
  };

  const loginHandler = async () => {
    setLoading(true);

    if (userType === "individual") {
      const data = {
        email,
        password,
        userType: userType,
        deviceType: `${browserName} on ${osName}`,
      };
      try {
        const response = await axios.post(`${getAPIBaseUrl()}/login`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        storeDispatch(setUserDetails(response?.data?.user));
        storeDispatch(setUserActionType("individual"));
        setLoading(false);
        setLoginErrorMessage("");
        toast.success("Logged in successfully");

        if (response && response.data && !response.data.is_verified) {
          setVerifyEmailModal(true);

          await axios.post(`${getAPIBaseUrl()}/send-verification-code`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        } else {
          if (response.data.code === "2") {
            navigate("/verify-mfa");
          } else {
            checkPaymentStatus(response?.data?.user?.email);
          }
        }

        if (rememberMe) {
          localStorage.setItem("email", email);
          localStorage.setItem("password", password);
        } else {
          sessionStorage.setItem("email", email);
          sessionStorage.setItem("password", password);
        }
      } catch (error) {
        storeDispatch(setUserDetails({}));
        setLoading(false);
        storeDispatch(setUserActionType("individual"));
        setLoginErrorMessage(error?.response?.data?.error);
      }
    } else {
      const data = {
        email,
        password,
        publicEnterpriseId: enterpriseUniqueId,
        userType: userType,
      };
      try {
        const response = await axios.post(`${getAPIBaseUrl()}/login`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        storeDispatch(setUserDetails(response?.data));
        setLoading(false);
        setLoginErrorMessage("");
        storeDispatch(setUserActionType("enterprise"));
        toast.success("Logged in successfully");
        navigate("/");
      } catch (error) {
        storeDispatch(setUserDetails({}));
        setLoading(false);
        storeDispatch(setUserActionType("enterprise"));
        setLoginErrorMessage(error?.response?.data?.error);
      }
    }
  };

  const googleLoginHandler = useGoogleLogin({
    onSuccess: async (tokenResponse) => handleSuccess(tokenResponse),
  });

  const msLoginHandler = () => {
    if (authUrl) {
      window.location.href = authUrl;
    }
  };

  const onSubmitHandler = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/forgot-password`,
        {
          email: resetEmail,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Reset link sent successfully!");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchAuthUrl = async () => {
      axios
        .get(`${getAPIBaseUrl()}/ms-auth-url`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setAuthUrl(res.data.auth_url);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchAuthUrl();
  }, []);

  useEffect(() => {
    const savedEmail =
      localStorage.getItem("email") || sessionStorage.getItem("email");
    const savedPassword =
      localStorage.getItem("password") || sessionStorage.getItem("password");

    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
    }
  }, []);

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 text-white bg-white min-h-dvh">
        <div className="p-4 h-dvh hidden md:block">
          <img
            alt="AgentGG Background"
            src={loginImage}
            className="rounded-3xl object-contain h-full w-full bg-[black]"
          />
        </div>
        <div className="p-4">
          <div className="flex flex-col justify-center items-center max-w-md h-full mx-auto">
            <h1 className="text-center text-4xl md:text-5xl text-black-1100 font-semibold max-w-sm mb-4">
              Let's login to your account first
            </h1>

            <div className="grid w-full gap-3 grid-cols-2 text-black-800 mt-4">
              <button
                className="hover:opacity-90 w-full flex items-center justify-center py-2 border border-slate-50 rounded-2xl shadow-card bg-slate-200 focus:outline-none"
                onClick={googleLoginHandler}
              >
                <img alt="Google" src={GoogleIcon} className="w-6 h-6 mr-3" />
                <div className="font-medium text-sm">Google</div>
              </button>
              <button
                className="hover:opacity-90 w-full flex items-center justify-center py-2 border border-slate-50 rounded-2xl shadow-card bg-slate-200 focus:outline-none"
                onClick={msLoginHandler}
              >
                <img
                  alt="Microsoft"
                  src={microsoftIcon}
                  className="w-6 h-6 mr-3"
                />
                <div className="font-medium text-sm">Microsoft</div>
              </button>
            </div>
            <div className="w-full flex items-center justify-center py-4">
              <div className="w-full flex gap-2 items-center justify-center">
                <div className="h-[1px] grow bg-black-1100 bg-opacity-10"></div>
                <span className="text-sm text-black-1100 opacity-60 text-nowrap">
                  or with email
                </span>
                <div className="h-[1px] grow bg-black-1100 bg-opacity-10"></div>
              </div>
            </div>

            <div className="w-full text-black-1100 text-opacity-50 flex flex-col gap-3">
              <div className="flex sm:flex-row flex-col w-full gap-3">
                <div className="flex flex-col gap-2 grow">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="border-[1.5px] focus:border-opacity-50 px-3 py-4 placeholder-gray-500 text-black-900 rounded-2xl focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Email address"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="border-[1.5px] focus:border-opacity-50 px-3 py-4 placeholder-gray-500 text-black-900 rounded-2xl focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>

              <div className="flex items-center justify-between text-black-1100">
                <div className="flex gap-2">
                  <input
                    type="checkbox"
                    id="remember-me"
                    className="cursor-pointer border-[1px] border-solid border-[#ccc] rounded-lg"
                  />
                  <label htmlFor="remember-me" className="cursor-pointer">
                    Remember me
                  </label>
                </div>
                <button
                  className="appearance-none text-blue-50 hover:opacity-75"
                  onClick={() => setForgotPasswordModal(true)}
                >
                  Forgot password?
                </button>
              </div>

              <div className="pt-6">
                {loginErrorMessage && (
                  <span className="text-red-100 text-sm flex flex-row justify-center pb-1">
                    {loginErrorMessage}
                  </span>
                )}
                <button
                  className={`w-full h-12 bg-blue-50 text-white font-semibold rounded-2xl shadow-lg hover:brightness-125
 ${loading ? "opacity-70 cursor-not-allowed" : "cursor-pointer"}`}
                  onClick={loginHandler}
                  disabled={
                    !email ||
                    !password ||
                    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
                  }
                >
                  {loading ? <Loader /> : "Login"}
                </button>

                <span className="w-full flex items-center justify-center mt-6 text-black-1100 text-opacity-50">
                  Don't have an account?{" "}
                  <div
                    className="inline text-blue-50 hover:opacity-70 cursor-pointer ml-2"
                    onClick={() => navigate("/signup")}
                  >
                    Sign up
                  </div>
                </span>
                <a
                  className="flex justify-center mt-2 text-blue-50 hover:opacity-70"
                  href="https://generative-genius-llc.getrewardful.com/signup"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Join us and earn money</span>
                  <HiExternalLink />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={isForgotPasswordModal}
        handleClose={() => setForgotPasswordModal(false)}
        theme={theme}
      >
        <div className="w-full align-bottom bg-black-900 px-4 pt-5 pb-4 text-left sm:align-middle ">
          <div className="flex items-start justify-between w-full">
            <h3 className="text-lg leading-6 font-medium text-gray-100">
              Forgot Password
            </h3>
            <button
              className="text-gray-200 hover:text-gray-500 focus:outline-none"
              onClick={() => setForgotPasswordModal(false)}
            >
              <FaTimes className="w-5 h-5" />
            </button>
          </div>
          <div className="text-sm text-gray-300 mt-3">
            Enter your email address below and we'll send you a link to reset
            your password.
          </div>
          <div className="mt-4">
            <input
              type="email"
              className="w-full border-[1.5px] focus:border-opacity-50 px-3 py-4 placeholder-gray-500 text-black-900 rounded-2xl focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter your email address"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
            />
          </div>
          <div className="mt-6">
            <button
              onClick={onSubmitHandler}
              className="w-full flex flex-row justify-center items-center py-2 px-4 h-12 border border-transparent text-sm font-medium rounded-2xl text-white bg-blue-50 hover:bg-opacity-90 focus:outline-none"
            >
              {loading ? "Sending..." : "Send Reset Link"}
            </button>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        isOpen={isVerifyEmailModal}
        handleClose={() => {
          setVerifyEmailModal(false);
        }}
        theme={theme}
      >
        <div className="flex px-4 text-center bg-purple-100 py-5 px-10 w-full">
          <div className=" bg-purple-100 rounded-lg  pt-2 pb-4 w-full">
            <div className="w-full flex items-start justify-between">
              <h3 className="text-2xl leading-6 font-medium text-white">
                Verify Email
              </h3>
              <button
                className="text-gray-200 hover:text-gray-500 focus:outline-none"
                onClick={() => setVerifyEmailModal(false)}
              >
                <FaTimes className="w-5 h-5" />
              </button>
            </div>
            <div className="text-sm text-white text-left mt-3">
              We have sent a verification code to your email
            </div>
            <div className="mt-4">
              <input
                type="number"
                className="w-full border-[1.5px] focus:border-opacity-50 px-3 py-4 placeholder-gray-500 text-black-900 rounded-2xl focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter your verification code"
                value={verificationCode}
                onChange={(e) => {
                  setVerificationCode(e.target.value);
                }}
              />
            </div>

            <div className="mt-6">
              <button
                onClick={verifyUserHandler}
                className={`w-full cursor-pointer flex flex-row justify-center items-center  px-4 h-12 border border-transparent text-base font-medium rounded-md text-white bg-black-500    focus:outline-none
                  ${loading ? "opacity-70 cursor-not-allowed" : ""}
                  `}
              >
                {verifyCodeLoading ? "Verifying..." : "Verify User"}
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Login;
